<template>
  <div id="oauth">Вход в систему...</div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import config from '@/config';

export default defineComponent({
  name: 'OAuth',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    onMounted(async () => {
      await store.dispatch('auth/cancelRequests', route.query?.code);
      const response = await store.dispatch('auth/oauth', route.query?.code);
      if (response) {
        store.dispatch('auth/getUser', { isOAuthComponent: true }).then(() => {
          store.dispatch('notifications/getLastNotifications');
          store.dispatch('notifications/getNewNotifications');
          store.dispatch('refreshMenu');
          router.push({ name: 'gtin-search-search' });
        });
      } else {
        location.href = config.vueAppOauthErrorUrl;
      }
    });

    return {};
  },
});
</script>

<style lang="scss">
#oauth {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
</style>
