<template>
  <GS1BtnIcon :icon="['copy']" no-margin transparent small @click.stop="copy"></GS1BtnIcon>
</template>

<script lang="ts">
import Toast from '@/models/Toast';

import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'CopyButton',
  props: {
    /**
     * Текст для копирования
     */
    string: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const store = useStore();
    /**
     * Копирование в буфер обмена
     */
    const copy = () => {
      const tempInput = document.createElement('textarea');
      tempInput.value = props.string;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);

      store.dispatch('showToast', new Toast('', 'Текст скопирован'));
    };

    return {
      copy,
    };
  },
});
</script>

<style lang="scss">
.copy-button {
  .hidden-text {
    display: none;
  }
}
</style>
