<template>
  <div id="api-access">
    <Form id="api-access-form" v-slot="{ validate, setFieldError }" :class="classes" as="div">
      <div class="font-gray">
        Укажите реквизиты контактного лица, выберите из списка или укажите какие задачи вы собираетесь решать с помощью API и нажмите "Сохранить".
      </div>
      <GS1MultySelect
        v-model="userInfo.authorities"
        label="Полномочия"
        :error="validationErrors.authorities"
        label-fixed
        no-margin
        manual
        name="accesses"
        placeholder="Введите свой вариант или выберите варианты из списка"
        class="big-label"
        @create="addUserAuthority"
      >
        <GS1Option v-for="authority in authorities" :key="authority.id" :value="authority.id">{{ authority.name }}</GS1Option>
      </GS1MultySelect>
      <GS1Select
        v-if="api.code == 'srs'"
        v-model="userInfo.access"
        label="Уровень доступа"
        label-fixed
        no-margin
        :error="validationErrors.access"
        placeholder="Выберите уровень доступа"
        name="access"
        class="big-label"
      >
        <GS1Option value="read">Чтение данных продукции</GS1Option>
        <GS1Option value="write">Запись данных продукции</GS1Option>
      </GS1Select>

      <div class="big-label only-label">
        <label class="gs1-input__label fixed">ФИО и должность</label>
      </div>

      <GS1Input v-model="userInfo.name" :error="validationErrors.name" no-margin label="" name="name" placeholder="ФИО" class="big-label"></GS1Input>
      <GS1Select
        v-model="userInfo.position_id"
        :error="validationErrors.position_id"
        no-margin
        placeholder="Выберите должность из списка"
        name="position_id"
      >
        <GS1Option v-for="position in positions" :key="position.id" :value="position.id">{{ position.name }}</GS1Option>
      </GS1Select>

      <div class="big-label only-label">
        <label class="gs1-input__label fixed">Контактная информация</label>
      </div>

      <div class="confirm-input">
        <GS1Input v-model="userInfo.email" :error="validationErrors.email" no-margin required placeholder="E-mail" name="email"></GS1Input>

        <div v-if="infoChecked.email == 3" class="status-message">
          Подтверждён
          <GS1Icon icon="success"></GS1Icon>
        </div>
      </div>

      <div v-if="infoChecked.email != 3 && infoChecked.email != 4" class="confirm">
        <GS1Input v-if="infoChecked.email == 2" v-model="confirmCodes.email" no-margin placeholder="Код"></GS1Input>
        <GS1Btn :disabled="userInfo.email.length == 0" no-margin primary @click="check('email')"> Подтвердить </GS1Btn>
        <GS1BtnIconEdit
          v-if="infoChecked.email == 2"
          :icon="['reset-revert']"
          :disabled="userInfo.email.length == 0"
          transparent
          no-margin
          @click="resend('email')"
        >
          <template v-if="confirmCooldown.email > 0">
            Повторно через: <span>{{ confirmCooldown.email }} сек</span>
          </template>
          <template v-else> Отправить код еще раз </template>
        </GS1BtnIconEdit>
      </div>

      <div class="confirm-input">
        <GS1Input
          v-model="userInfo.phone"
          v-maska="'+7 (###) ###-##-##'"
          :error="validationErrors.phone"
          no-margin
          required
          placeholder="Телефон"
          name="phone"
        ></GS1Input>
        <div v-if="infoChecked.phone == 3" class="status-message">
          Подтверждён
          <GS1Icon icon="success"></GS1Icon>
        </div>
      </div>

      <div v-if="infoChecked.phone != 3 && infoChecked.phone != 4" class="confirm">
        <GS1Input v-if="infoChecked.phone == 2" v-model="confirmCodes.phone" no-margin placeholder="Код"></GS1Input>
        <GS1Btn :disabled="userInfo.phone.length == 0" no-margin primary @click="check('phone')"> Подтвердить </GS1Btn>
        <GS1BtnIconEdit
          v-if="infoChecked.phone == 2"
          :icon="['reset-revert']"
          :disabled="userInfo.email.length == 0"
          transparent
          no-margin
          @click="resend('phone')"
        >
          <template v-if="confirmCooldown.phone > 0">
            Повторно через: <span>{{ confirmCooldown.phone }} сек</span>
          </template>
          <template v-else> Отправить код еще раз </template>
        </GS1BtnIconEdit>
      </div>
      <GS1InputCheck
        v-model="userInfo.personalAgreement"
        :error="validationErrors.personalAgreement"
        no-margin
        name="info-agreement"
        class="info-agreement"
      >
        Даю согласие на обработку персональных данных
        <GS1BtnIcon
          v-gs1-tooltip.left="{ text: 'Текст о том, как мы будем хранить, использовать и обрабатывать эти данные', mode: ViewMode }"
          :icon="['question-big']"
          class="info-agreement-btn"
          transparent
          no-margin
          tiny
        ></GS1BtnIcon>
      </GS1InputCheck>
      <GS1Btn
        :disabled="!userInfo.personalAgreement || requestSent"
        class="save-btn"
        primary
        no-margin
        @click.prevent="requestAccess(validate, setFieldError)"
      >
        Сохранить
      </GS1Btn>
    </Form>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, onUnmounted, inject, ref, watch } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import User from '@/modules/auth/models/User';
import type EventBus from '@/models/EventBus';
import { Form } from 'vee-validate';
import app from '@/main';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'ApiAccess',
  components: {
    Form,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const bus: EventBus | undefined = inject('bus');

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    /**
     * Значение темы приложения (темная/светлая)
     */
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    /**
     * Объект с данными авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    /**
     * Объект API
     */
    const api: ComputedRef<Record<string, string>> = computed(() => store.state.vbgApi.api);

    /**
     * Код для загрузки компонента формы
     */
    const apiCode: ComputedRef<string> = computed(() => {
      return route.params.api[0].toUpperCase() + route.params.api.slice(1);
    });

    /**
     * Название пользовательского полномочия
     */
    const userAuthority: Ref<string> = ref('');

    /**
     * Статус отправки запроса
     */
    const requestSent: Ref<boolean> = ref(false);

    /**
     * Статус проверки данных. 1 - Не подтверждено, 2 - Отправлен код проверки, 3 - Подтверждено, 4 - Проверка не требуется
     */
    const infoChecked: Ref<{ [key: string]: number }> = ref({
      email: 1,
      phone: 1,
    });

    /**
     * Таймер запроса кода
     */
    const confirmCooldown: Ref<{ [key: string]: number }> = ref({
      email: 0,
      phone: 0,
    });

    /**
     * Таймер запроса кода
     */
    const confirmCooldownIntervals: Ref<{ [key: string]: any }> = ref({
      email: null,
      phone: null,
    });

    /**
     * Ошибки валидации
     */
    const validationErrors: Ref<{ [key: string]: string }> = ref({
      position_id: '',
      phone: '',
      email: '',
      name: '',
      access: '',
      personalAgreement: '',
      authorities: '',
      userAuthority: '',
    });

    /**
     * Данные пользователя
     */
    const userInfo: Ref<{ [key: string]: any }> = ref({
      email: '',
      phone: '',
      name: '',
      position_id: 0,
      access: '',
      personalAgreement: false,
      authorities: [],
      userAuthorities: [],
    });

    /**
     * Коды подтверждения
     */
    const confirmCodes: Ref<{ [key: string]: string }> = ref({
      email: '',
      phone: '',
    });

    /**
     * Список полномочий
     */
    const authorities: ComputedRef<Record<string, any>[]> = computed(() => {
      return store.state.vbgApi.authorities.concat(userInfo.value.userAuthorities);
    });

    /**
     * Список должностей
     */
    const positions: ComputedRef<Record<string, any>> = computed(() => store.state.positions);

    /**
     * Флаги загрузки
     */
    const srsAccess: ComputedRef<Record<string, boolean>> = computed(() => store.state.vbgApi.srsAccess);

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        ['mode-' + ViewMode.value]: true,
      };
    });

    /**
     * Проверка данных пользователя
     */
    const check = (type: string): void => {
      const emailRegex = new RegExp('^[\\w\\-\\.]+@([\\w\\-]+\\.)+[\\w\\-]{2,4}$');
      const phoneRegex = new RegExp('^\\+7\\s\\(\\d{3}\\)\\s\\d{3}-\\d{2}-\\d{2}$');

      if (type == 'email' && !emailRegex.test(userInfo.value.email)) {
        validationErrors.value[type] = 'Неверный формат e-mail';

        return;
      }

      if (type == 'phone' && !phoneRegex.test(userInfo.value.phone)) {
        validationErrors.value[type] = 'Неверный формат номера';

        return;
      }

      if (
        ((infoChecked.value[type] == 2 && confirmCodes.value[type].length > 0) || infoChecked.value[type] != 2) &&
        userInfo.value[type].length > 0
      ) {
        store
          .dispatch('auth/checkUserData', {
            type: type,
            value: userInfo.value[type],
            code: confirmCodes.value[type],
          })
          .then((result: Record<string, string>) => {
            if (confirmCooldownIntervals.value[type]) {
              clearInterval(confirmCooldownIntervals.value[type]);
            }
            confirmCooldown.value[type] = Number(result.seconds) || 60;
            confirmCooldownIntervals.value[type] = setInterval(() => {
              if (confirmCooldown.value[type] > 0) {
                confirmCooldown.value[type]--;
              }
            }, 1000);

            switch (result.status) {
              case 'error':
                validationErrors.value[type] = 'Неверный код';
                break;
              case 'soon':
                infoChecked.value[type] = 2;
                validationErrors.value[type] = 'Подождите ' + result.seconds + ' сек.';
                break;
              case 'created':
                infoChecked.value[type] = 2;
                validationErrors.value[type] = '';
                break;
              case 'confirmed':
                infoChecked.value[type] = 3;
                validationErrors.value[type] = '';
                break;
            }
          });
      } else {
        validationErrors.value[type] = 'Поле не может быть пустым';
      }
    };

    /**
     * Повторная отправка проверочного кода
     */
    const resend = (type: string): void => {
      infoChecked.value[type] = 1;
      check(type);
    };

    /**
     * Отправка запроса
     */
    const requestAccess = (): void => {
      if (requestSent.value) {
        return;
      }

      for (const field in validationErrors.value) {
        validationErrors.value[field] = '';
      }

      if (!userInfo.value.personalAgreement) {
        validationErrors.value.personalAgreement = 'Необходимо дать согласие на обработку данных';
      } else if (infoChecked.value.email !== 3 && infoChecked.value.email !== 4) {
        validationErrors.value.email = 'Необходимо подтвердить e-mail';
      } else if (infoChecked.value.phone !== 3 && infoChecked.value.phone !== 4) {
        validationErrors.value.phone = 'Необходимо подтвердить телефон';
      } else {
        requestSent.value = true;

        store
          .dispatch('vbgApi/requestAccess', { api: api.value.code, userInfo: userInfo.value })
          .then((result: Record<string, any>) => {
            if (result.success) {
              store.dispatch('auth/getUser', { force: true });
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              app.addAlert({ text: 'Ваш уровень доступа к API был изменен', type: 'success', time: 5000 });
              router.push({ name: 'gs1-api' });
            } else {
              if (typeof result.errors !== 'undefined') {
                for (const field in result.errors) {
                  const error = result.errors[field];
                  if (field in validationErrors.value) {
                    validationErrors.value[field] = error[0];
                  }
                }
              }
            }
          })
          .finally(() => {
            requestSent.value = false;
          });
      }
    };

    /**
     * Добавление пользовательского полномочия
     */
    const addUserAuthority = (userAuthority: string): void => {
      validationErrors.value['userAuthority'] = '';
      if (userAuthority.length < 4) {
        validationErrors.value['userAuthority'] = 'Название не может быть короче 4 символов';
      } else {
        const timestamp = Date.now();
        userInfo.value.userAuthorities.push({
          id: timestamp,
          name: userAuthority,
          custom: true,
        });
        userInfo.value.authorities.push(timestamp);
      }
    };

    const onCloseSidePanel = (): void => {
      router.push({ name: 'gs1-api' });
    };

    onMounted(() => {
      store.commit('setH1', 'Доступ к API');
      store.dispatch('auth/getUser').then(() => {
        if (userInfo.value.name.length == 0 && user.value.name) userInfo.value.name = user.value.name;
        if (userInfo.value.email.length == 0 && user.value.email) userInfo.value.email = user.value.email;
        if (userInfo.value.phone.length == 0 && user.value.phone) userInfo.value.phone = user.value.phone;

        store.dispatch('vbgApi/getApi', route.params.api).then(() => {
          if (user.value.gs1ApiAccess(api.value.code) != 1) {
            router.push({ name: 'gs1-api' });
          }

          store.dispatch('vbgApi/getAuthorities', { api: api.value.code });
          store.dispatch('getPositions');
          if (api.value.code == 'srs') {
            store.dispatch('vbgApi/getSrsAccess').then(() => {
              if (srsAccess.value.write) userInfo.value.access = 'write';
              else if (srsAccess.value.read) userInfo.value.access = 'read';
            });
          }
        });
      });
      // Слушатель события клика на кнопке закрытия правой панели
      if (bus) bus.on('close-sidepanel', onCloseSidePanel);
    });

    onUnmounted(() => {
      if (bus) bus.off('close-sidepanel', onCloseSidePanel);
    });

    watch(
      () => user.value,
      () => {
        if (userInfo.value.name.length == 0 && user.value.name) userInfo.value.name = user.value.name;
        if (userInfo.value.email.length == 0 && user.value.email) userInfo.value.email = user.value.email;
        if (userInfo.value.phone.length == 0 && user.value.phone) userInfo.value.phone = user.value.phone;
      },
      { deep: true }
    );
    watch(
      () => userInfo.value.authorities,
      () => {
        userInfo.value.authorities.sort(function (a: number, b: number) {
          return a - b;
        });
      }
    );
    watch(
      () => userInfo.value.email,
      () => {
        if (userInfo.value.email !== user.value.email) {
          infoChecked.value.email = 1;
        }
      }
    );
    watch(
      () => userInfo.value.phone,
      () => {
        if (userInfo.value.phone !== user.value.phone) {
          infoChecked.value.phone = 1;
        }
      }
    );

    return {
      user,
      api,
      apiCode,
      loading,
      infoChecked,
      userInfo,
      confirmCodes,
      srsAccess,
      ViewMode,
      check,
      resend,
      requestAccess,
      validationErrors,
      authorities,
      positions,
      userAuthority,
      addUserAuthority,
      confirmCooldown,
      requestSent,
      classes,
    };
  },
});
</script>

<style lang="scss">
#api-access {
  .big-label {
    .gs1-multyselect__label,
    .gs1-input__label,
    .gs1-select__label {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: var(--margin-x3);
    }
    &.only-label {
      margin-top: var(--margin-x3);
    }
  }
  .confirm-input {
    position: relative;
    .gs1-element.gs1-input .gs1-input__bar .gs1-input__error .gs1-icon i {
      background-color: var(--gs1-color-main-14);
    }
    .status-message {
      position: absolute;
      right: var(--margin-x2);
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      height: 16px;
      color: var(--color-main-7);
      .gs1-icon {
        margin-left: var(--margin-x2);
      }
      .gs1-icon__icon {
        background-color: var(--color-main-7);
      }
    }
  }
  .info-agreement-btn {
    margin-left: var(--margin-x2);
  }
  .confirm .gs1-btn-icon-edit__value span {
    color: var(--color-main-8);
  }
  .confirm,
  .gs1-input-check__label {
    display: flex;
  }
  .confirm .gs1-element + .gs1-element {
    margin-left: var(--margin-x3);
  }
  .gs1-element.gs1-btn {
    &.save-btn {
      width: 100%;
    }
  }
  #api-access-form {
    > .gs1-element,
    > .confirm,
    > .confirm-input {
      margin-top: var(--margin-x3);
    }
    &.mode-dark {
      .font-gray {
        color: var(--color-main-4);
      }
    }
    &:not(.mode-dark) {
      .gs1-element.gs1-btn {
        &.disabled {
          border-color: var(--color-main-10);
        }
      }
    }
    margin: 0 0 0 2px;
  }
}
</style>
