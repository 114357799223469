<template lang="html">
  <div v-if="company && company[queryKey] && queryKey.length" :class="classes">
    {{ company[queryKey] }}
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'CompanyResultHeader',
  setup() {
    const store = useStore();
    const route = useRoute();

    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    const queryKey = computed(() => {
      switch (route.name) {
        case 'company-search-by-gln-result':
          return 'gln';
        case 'company-search-by-inn-result':
          return 'inn';
        case 'company-search-by-key-result':
          return route.params.key;
        default:
          return '';
      }
    });

    const company: ComputedRef<any> = computed(() => store.state.companySearch.company);
    const classes: ComputedRef<Record<string, boolean>> = computed(() => {
      return {
        error: company.value.hasError,
        success: !company.value.hasError,
        'company-result-header': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    return {
      company,
      classes,
      queryKey,
    };
  },
});
</script>
<style lang="scss" scoped>
.company-result-header {
  padding: var(--padding-x1) var(--padding-x2);
  border-radius: var(--border-radius-x2);
  height: 32px;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    width: 2px;
    height: 10px;
    margin-right: var(--margin-x1);
  }

  &.success {
    background-color: var(--color-main-15);
    &::before {
      background-color: var(--color-main-7);
    }
  }

  &.error {
    background-color: var(--color-main-16);
    &::before {
      background-color: var(--color-main-5);
    }
  }

  &.warning {
    background-color: var(--color-main-17);
    &::before {
      background-color: var(--color-main-18);
    }
  }
  &.mode-dark {
    color: var(--color-main-f);
    line-height: 21px;

    &.success {
      background-color: var(--color-additional-14);
    }

    &.error {
      background-color: var(--color-danger-950);
    }

    &.warning {
      background-color: var(--color-main-26);
    }
  }
}
</style>
