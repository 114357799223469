<template>
  <div :class="{ hidden: hidden, active: !hidden }" class="result__block_wrapper">
    <div class="result__heading">
      <slot name="title"></slot>
      <GS1BtnIcon no-margin :icon="['arrow-down', 'medium']" @click="toggle"></GS1BtnIcon>
    </div>
    <div class="result__block" :style="`height: ${currentHeight}px;`">
      <div ref="content" class="result__content">
        <slot name="default"></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, type Ref } from 'vue';

export default defineComponent({
  name: 'ResultBlock',
  setup() {
    const hidden: Ref<boolean> = ref(true);
    const content: Ref<HTMLDivElement | null> = ref(null);
    const currentHeight: Ref<number | undefined> = ref(0);

    const calculateHeight = () => {
      currentHeight.value = !hidden.value ? content.value?.getBoundingClientRect()?.height : 0;
    };
    const toggle = () => {
      hidden.value = !hidden.value;
      calculateHeight();
    };

    return { hidden, content, currentHeight, toggle };
  },
});
</script>
<style lang="scss">
.result__block {
  overflow: hidden;
  transition: var(--transition-super-fast-smooth);
}
.result__content {
  display: block;
  padding: 1px 0;
}
.result__block_wrapper.active .result__heading .gs1-icon__icon {
  transform: rotate(180deg);
}
.result__heading {
  font-weight: 600;
  font-size: 13px;
  margin: var(--margin-x3) 0;
  color: var(--color-main-2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .gs1-btn-icon:hover {
    background-color: var(--color-main-23);
  }
}
.mode-dark {
  .result__heading {
    color: var(--color-main-f);
    .gs1-btn-icon:hover {
      background-color: var(--color-additional-17);
    }
  }
}
</style>
