import config from '@/config';
import router from '@/router';

export const openOAuth = (showExpiredPage = false): void => {
  const returnURL = location.protocol + '//' + location.host;
  const baseUrl = config.oauthFormUrl;
  const accessToken = router.currentRoute.value.query.access_token as string;

  const data: Record<string, string> = {
    grant_type: 'authorization_code',
    response_type: 'code',
    client_id: config.oauthLogin,
    scope: 'userInfo',
    redirect_uri: returnURL + config.oauthRedirectUrl,
  };
  if (typeof accessToken !== 'undefined') {
    data['redirected_token'] = accessToken;
  }
  if (showExpiredPage) {
    data['show_expired_page'] = 'true';
  }
  const params = new URLSearchParams(data);

  const url = baseUrl + params.toString();
  location.href = url;
};
