import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  ref: "inner",
  class: "short-text-inner"
}
const _hoisted_2 = {
  key: 0,
  class: "fas fa-chevron-down toggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "outer",
    style: _normalizeStyle({ 'max-height': _ctx.height + 'px' }),
    class: _normalizeClass(["short-text", { expanded: _ctx.expanded }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ], 512),
    (_ctx.showToggle)
      ? (_openBlock(), _createElementBlock("i", _hoisted_2))
      : _createCommentVNode("", true)
  ], 6))
}