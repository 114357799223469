import axios, { AxiosResponse } from 'axios';

export default {
  namespaced: true,
  state() {
    return {
      /**
       * Массив с историей изменений
       */
      releases: [],
    };
  },
  mutations: {
    setReleases(state: Record<string, any>, releases: Record<string, any>[]): void {
      state.releases = releases;
    },
  },
  actions: {
    /**
     * Получение истории изменений
     *
     * @param context
     */
    async getChangelogs({ commit }: { commit: any }): Promise<boolean> {
      commit('setLoading', { flagName: 'changelog', flagState: true }, { root: true });

      try {
        await axios.get('changelog').then((response: AxiosResponse) => {
          commit('setReleases', response.data);
        });

        return true;
      } catch (errors) {
        commit('setChangelogs', []);

        return false;
      } finally {
        commit('setLoading', { flagName: 'changelog', flagState: false }, { root: true });
      }
    },
  },
  modules: {},
};
