<template>
  <component :is="'layout-' + layout">
    <RouterView name="default" />
    <ModalConfirm />
    <GS1ActivityConfirmation v-if="logoutModalShow" @continue="onContinue" @decline="onDecline" />
    <GS1AlertBar ref="alertbar" class="alert-bar"></GS1AlertBar>
  </component>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, watch, provide } from 'vue';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import ModalConfirm from '@/components/ModalConfirm.vue';
import SessionPopup from '@/components/SessionPopup.vue';
import ToastNotification from '@/components/ToastNotification.vue';
import User from '@/modules/auth/models/User';
import Toast from '@/models/Toast';
import useEvent from './utils/useEvent';
import type GS1AlertBarItem from './models/GS1AlertBarItem';
import '@/assets/fonts/stylesheet.scss';
import { openOAuth } from '@/utils/openOauth';
import config from '@/config';

export default defineComponent({
  components: {
    ModalConfirm,
    SessionPopup,
    ToastNotification,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const eventBus = useEvent();
    const alertbar = ref();
    const viewMode = computed(() => store.state.ViewMode);

    const layout = computed(() => route?.meta?.layout || 'default');

    /**
     * Объект с данными авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    /**
     * Флаг отображения плашки сессии
     */
    const logoutModalShow: ComputedRef<boolean> = computed(() => store.state.auth.logoutModalShow);

    /**
     * Проверка на то, что текущая страница - форма авторизации для админа
     */
    const onAdminLoginPage: ComputedRef<boolean> = computed(() => {
      return route.name == 'login';
    });

    /**
     * Флаг скрытия отступов у контента
     */
    const noPadding: ComputedRef<boolean> = computed(() => store.state.noPadding);

    /**
     * Отображать оверлей
     */
    const showOverlay: ComputedRef<boolean> = computed(() => store.state.showOverlay);

    /**
     * Функция, вызываемая при клике по оверлею
     */
    const overlayCallback: ComputedRef<Function> = computed(() => store.state.overlayCallback);

    const addAlert = (data: GS1AlertBarItem) => {
      if (alertbar.value?.alerts) alertbar.value.alerts.push(data);
    };

    /**
     * Клик по оверлею
     */
    const clickOverlay = (): void => {
      overlayCallback.value();
      store.commit('setOverlay', false);
    };

    /**
     * Выбор продолжить сессию
     */
    const onContinue = (): void => {
      store.commit('auth/hideLogoutModal');

      // Ставиться текущее время, чтобы не сработало глобальное событие по клику и не проихошло одновременно 2 запроса на refresh
      const currentTimestamp = Math.floor(Date.now() / 1000);
      localStorage.setItem('last_token_refresh', '' + currentTimestamp);

      store.dispatch('auth/refreshToken', true);
    };

    /**
     * Выбор закончить сессию
     */
    const onDecline = (): void => {
      store.dispatch('auth/logout').finally(() => {
        location.href = `${config.appLogoutUrl}?redirect_uri=${config.appUrl}`;
      });
    };

    provide('bus', eventBus);
    provide('addAlert', addAlert);
    provide('viewMode', viewMode);

    onMounted(() => {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      localStorage.setItem('last_token_refresh', '' + currentTimestamp);

      window.addEventListener('scroll', () => {
        document.querySelectorAll('.expanded-block').forEach((el: Element) => {
          el.remove();
        });
      });

      // Обновлене сессии при любом действии пользователя
      document.addEventListener('mousemove', () => {
        store.dispatch('auth/refreshToken');
      });
      document.addEventListener('keydown', () => {
        store.dispatch('auth/refreshToken');
      });
      document.addEventListener('click', () => {
        store.dispatch('auth/refreshToken');
      });
      window.addEventListener('focus', () => {
        store.dispatch('auth/refreshToken');
      });
    });

    return {
      layout,
      user,
      onAdminLoginPage,
      noPadding,
      showOverlay,
      overlayCallback,
      alertbar,
      openOAuth,
      addAlert,
      clickOverlay,
      logoutModalShow,
      onContinue,
      onDecline,
    };
  },
});
</script>

<style src="vue3-datepicker/dist/vue3-datepicker.css"></style>
<style src="@/assets/swiper/navigation.min.css"></style>
<style src="@/assets/swiper/swiper.min.css"></style>
<style src="@/assets/GothamPro.css"></style>
<style src="@/assets/SFUIDisplay.css"></style>
<style src="@/assets/icomoon/style.css"></style>
<style lang="scss">
@import '@/assets/adminlte/adminlte.scss';
@import '@/assets/icheck/icheck-bootstrap.min';
@import '@/assets/fontawesome/fontawesome.scss';
@import '@/assets/fontawesome/regular.scss';
@import '@/assets/fontawesome/solid.scss';
@import '@/assets/fontawesome/brands.scss';
@import '@/assets/style.scss';
@import '@/assets/main.scss';
@import '@/assets/company-search.scss';
</style>
