import pusher from '@/pusher';
import store from '@/store';

export default {
  subscribe() {
    pusher.on('open', () => {
      pusher.subscribe('VbgApi');

      pusher.onEvent('ApprovedRequest', function (data: Record<string, any>) {
        if (store.state.auth.user.id == data.user_id) {
          store.dispatch('auth/getUser', true);
        }
      });

      pusher.onEvent('AccessRequested', function (data: Record<string, any>) {
        if (store.state.auth.user.id == data.user_id) {
          store.dispatch('auth/getUser', true);
        }
      });
    });
  },
};
