export default {
  updated: (el: any) => {
    el.rows = 1;
    el.style.overflowY = 'hidden';

    const styles = window.getComputedStyle(el);
    const paddingTop = parseInt(styles.paddingTop);
    const paddingBottom = parseInt(styles.paddingBottom);
    const padding = paddingTop + paddingBottom;
    const initialHeight = (parseInt(styles.height) - padding) / el.rows;
    const scrollHeight = el.scrollHeight - padding;
    const newRows = Math.ceil(scrollHeight / initialHeight);
    el.rows = newRows;
  }
};