<template>
  <div class="component__modal-confirm">
    <GS1Modal v-model:active="active" hide-close-btn>
      <template #default>
        <h4 v-html="text"></h4>

        <div class="component__modal-confirm__btn-bar">
          <GS1Btn min accent no-margin @click="onCancel">{{ cancelText }}</GS1Btn>
          <GS1Btn min success no-margin @click="onConfirm">{{ confirmText }}</GS1Btn>
        </div>
      </template>
    </GS1Modal>
  </div>
</template>
<script lang="ts">
import type { ModalConfirm } from '../models/Modals';
import { defineComponent, inject, ref } from 'vue';
import type { Ref } from 'vue';
import type EventBus from '@/models/EventBus';

export default defineComponent({
  name: 'ModalConfirm',
  setup() {
    const bus: EventBus | undefined = inject('bus');
    const active: Ref<boolean> = ref(false);
    const modalText: Ref<string> = ref('');
    const modalConfirmText: Ref<string> = ref('Ок');
    const modalCancelText: Ref<string> = ref('Отмена');
    const modalConfirm: Ref<CallableFunction> = ref(() => ({}));
    const modalCancel: Ref<CallableFunction> = ref(() => ({}));
    const modalCancelMain = () => {
      if (modalCancel.value && modalCancel.value) modalCancel.value();
      active.value = false;
    };
    const modalConfirmMain = () => {
      if (modalConfirm.value && modalConfirm.value) modalConfirm.value();
      active.value = false;
    };

    bus?.on('confirm', ({ text, confirmText, cancelText }: ModalConfirm, onConfirm: CallableFunction, onCancel: CallableFunction) => {
      if (text) modalText.value = text;
      if (onConfirm) modalConfirm.value = onConfirm;
      if (onCancel) modalCancel.value = onCancel;
      if (confirmText) modalConfirmText.value = confirmText;
      if (cancelText) modalCancelText.value = cancelText;
      active.value = true;
    });

    return {
      active,
      text: modalText,
      confirmText: modalConfirmText,
      cancelText: modalCancelText,
      onConfirm: modalConfirmMain,
      onCancel: modalCancelMain,
    };
  },
});
</script>

<style lang="scss">
.component__modal-confirm {
  .component__modal-confirm__btn-bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: var(--margin-x35);

    .gs1-btn + .gs1-btn {
      margin-left: var(--margin-x25);
    }
  }
}
</style>
