<template>
  <header :class="classes">
    <div class="main-header__logo-container">
      <GS1Icon
        :icon="active ? 'close-big' : 'menu-hamburger'"
        big
        no-margin
        class="main-header__logo-container__closeButton"
        @click="toggleSidebar()"
      ></GS1Icon>
      <router-link :to="{ name: 'home' }" class="main-header__logo-container__link">
        <img class="main-header__logo-container__logo" :src="logoImage" alt="GS1 Russia" />
      </router-link>
      <div class="main-header__logo-container__text">
        <h2 class="logo-container__text__main-text display-bold display-secondary">Добро пожаловать в GS1 Russia</h2>
        <span class="logo-container__text__secondary-text display-secondary">The Global Language of Business</span>
      </div>
      <div class="main-header__flag display-small">Rich</div>
      <GS1ViewModeBtn v-model="ViewMode"></GS1ViewModeBtn>
    </div>
    <div class="main-header__tools">
      <GS1UserBtn
        :is-auth="isAuth"
        :tooltip-text="tooltipText"
        :top-text="UserHeader?.main"
        :top-text-additional="UserHeader?.additional"
        :bottom-text="CompanyHeader?.main"
        :bottom-text-additional="CompanyHeader?.additional"
        @auth-click="openOAuth()"
        @logout-click="onLogout()"
      ></GS1UserBtn>
      <div v-if="activeServicesMenu" class="main-header__tools__services-menu__container">
        <i class="main-header__tools__rectangle"></i>
        <GS1ServicesMenu :endpoint="gs1adminEndpoint" :stage="stage"></GS1ServicesMenu>
      </div>
    </div>
  </header>
</template>
<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue';
import type { WritableComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import type { ComputedRef, Ref } from 'vue';
import config from '@/config';
import { openOAuth } from '@/utils/openOauth';
export default defineComponent({
  name: 'MainHeader',
  setup() {
    const store = useStore();
    const route = useRoute();
    const user = computed(() => store.state.auth.user);
    const lkUser = computed(() => store.state.auth.userLk);
    const UserHeader: ComputedRef<{ main: string; additional: string }> = computed(() => ({
      main:
        [lkUser.value?.gs_user?.last_name || '', lkUser.value?.gs_user?.first_name || '', lkUser.value?.gs_user?.middle_name || '']
          .filter((v) => !!v)
          .join(' ') || '',
      additional: lkUser.value?.gs_user?.gln || '',
    }));
    const CompanyHeader: ComputedRef<{ main: string; additional: string }> = computed(() => ({
      main: lkUser.value?.gs_organization?.short_name || lkUser.value?.gs_organization?.name || '',
      additional: lkUser.value?.gs_organization?.gln || '',
    }));
    const accessToken = computed(() => store.state.auth.accessToken);
    const isAuth: ComputedRef<boolean> = computed(() => store.state.auth?.user?.loggedIn);
    const gs1adminEndpoint = ref(config.gs1adminEndpoint);
    const stage = config.appEnv;
    const activeServicesMenu = ref(true);
    const sidebarActive: Ref<boolean> = ref(true);
    const active = computed({
      get: () => store.state.leftMenuActive,
      set: (value: string) => store.commit('setLeftMenuActive', value),
    });
    const tooltipText: ComputedRef<string> = computed(() => {
      const text: string[] = [];

      if (user?.value?.companyName?.length > 0) {
        text.push(user.value.companyName);
      }
      if (user.value.gln.length > 0) {
        text.push(user.value.gln);
      }
      if (user.value.fullName.length > 0) {
        text.push(user.value.fullName);
      }

      return text.join(', ');
    });
    const bottomLabel: ComputedRef<string> = computed(() => {
      return user.value.companyName + ` (${user.value.companyGln})`;
    });
    const topLabel: ComputedRef<string> = computed(() => {
      return (user.value.fullName || user.value.gln) + ` (${user.value.gln})`;
    });
    const toggleSidebar = () => {
      sidebarActive.value = !sidebarActive.value;
      store.commit('setLeftMenuActive', sidebarActive.value);
    };

    /**
     * Переключение темы со светлой на темную
     */
    const ViewMode: WritableComputedRef<string> = computed({
      get: () => store.state.ViewMode,
      set: (value: string) => store.commit('setViewMode', value),
    });

    const logoImage = computed(() => {
      if (ViewMode.value === 'dark') return require('./../assets/logoDark.svg');
      else if (ViewMode.value === 'light') return require('./../assets/logo.svg');
      else return require('./../assets/logo.svg');
    });

    const oauthLogout = () => (window.location.href = `${config.appLogoutUrl}?redirect_uri=${config.appUrl}`);

    const onLogout = () => {
      store.dispatch('auth/logout').finally(() => oauthLogout());
    };
    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'main-header__container': true,
        ['mode-' + ViewMode.value]: true,
      };
    });
    watch(
      () => route.path,
      () => {
        if (route.path === '/auth/login') activeServicesMenu.value = false;
        else activeServicesMenu.value = true;
      },
    );
    watch(ViewMode, (newValue) => store.commit('setMode', newValue));
    return {
      user,
      isAuth,
      active,
      sidebarActive,
      UserHeader,
      CompanyHeader,
      ViewMode,
      classes,
      logoImage,
      onLogout,
      openOAuth,
      toggleSidebar,
      gs1adminEndpoint,
      stage,
      activeServicesMenu,
      tooltipText,
      topLabel,
      bottomLabel,
    };
  },
});
</script>
<style lang="scss">
.main-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--padding-x25) var(--padding-x4);
  background-color: var(--color-main-f);
  border-bottom: 1px solid var(--color-main-10);
  min-width: 100%;
  height: 60px;
  position: relative;
  z-index: 5;
  .main-header__tools {
    display: flex;
    align-items: center;
    .gs1-element.gs1-user-btn .gs1-user-btn__user-menu {
      z-index: 3;
    }
    .gs1-user-btn__user-value__name {
      color: var(--color-main-3);
    }
    .main-header__tools__rectangle {
      width: 1px;
      height: 16px;
      background-color: var(--color-main-10);
      margin: 0 var(--margin-x3);
    }
    .main-header__tools__services-menu__container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .gs1-view-mode-btn {
    margin-left: var(--margin-x4);
  }
  &.mode-dark {
    background-color: var(--color-main-2);
    border-bottom-color: var(--color-additional-10);

    .main-header__logo-container {
      .logo-container__text__main-text {
        color: var(--color-main-f);
      }
      .logo-container__text__secondary-text {
        color: var(--color-main-4);
      }
    }
    .main-header__tools__services-menu__container {
      .gs1-element.gs1-icon {
        .gs1-icon__icon {
          background-color: var(--color-main-4);
        }
      }
    }
    .main-header__tools__rectangle {
      background-color: var(--color-main-22);
    }
  }
  .main-header__logo-container__closeButton {
    display: none !important;
    cursor: pointer;

    @media screen and (max-width: 1024px) {
      display: block !important;
      margin-right: var(--margin-x4);
    }
  }
}
.main-header__logo-container__link {
  display: block;
  height: 50px;
  margin-right: var(--margin-x3);
}
.main-header__logo-container__logo {
  display: block;
  height: 100%;
}
.main-header__logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-container__text__secondary-text {
  color: var(--color-main-3);
}
.logo-container__text__main-text {
  color: var(--color-main-1);
  margin: 0;
}
.main-header__flag {
  background-color: #ff3607;
  color: #fff;
  padding: 5px 16px;
  border-radius: var(--border-radius-x2);
  top: 8px;
  right: -8px;
  margin-left: var(--margin-x4);
}
</style>
