import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "slider__container" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GS1Icon = _resolveComponent("GS1Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "slider__container__slides",
      style: _normalizeStyle(`transform: translateX(${_ctx.translateX}px);`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sliders, (slide, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          _createElementVNode("img", {
            src: slide.value,
            class: "slider__img"
          }, null, 8, _hoisted_2)
        ]))
      }), 128))
    ], 4),
    _createElementVNode("button", {
      class: "slider__button prev-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.prevSlider && _ctx.prevSlider(...args)))
    }, [
      _createVNode(_component_GS1Icon, {
        class: "prev-arrow",
        color: "#a7a7a7",
        icon: "arrow-left",
        min: ""
      })
    ]),
    _createElementVNode("button", {
      class: "slider__button next-button",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextSlider && _ctx.nextSlider(...args)))
    }, [
      _createVNode(_component_GS1Icon, {
        class: "next-arrow",
        color: "#a7a7a7",
        icon: "arrow-right",
        min: ""
      })
    ])
  ]))
}