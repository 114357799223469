export default {
  mounted: (el: any) => {
    el.classList.add('has-tooltip');
    
    el.positionTooltip = () => {
      const tooltip = el.querySelector('.tooltip');

      if (tooltip) {
        const elTop = el.getBoundingClientRect().top;
        const elLeft = el.getBoundingClientRect().left;
        const tooltipTop = elTop - tooltip.offsetHeight;
        let tooltipLeft = elLeft + (el.offsetWidth / 2) - (tooltip.offsetWidth / 2);
        if (tooltipLeft + tooltip.offsetHeight > window.outerWidth) tooltipLeft = window.outerWidth - tooltip.offsetWidth;
  
        tooltip.style.left = tooltipLeft + 'px';
        tooltip.style.top = (tooltipTop - 6) + 'px';
      }
    };
    
    el.addEventListener('mouseover', el.positionTooltip);
  },
  unmounted: (el: any) => {
    el.removeEventListener("mouseover", el.positionTooltip);
  },
};