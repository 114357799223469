<template>
  <MainHeader></MainHeader>
  <main :class="classes">
    <LeftMenu v-if="showLeftMenu"></LeftMenu>
    <div class="layout-default__content-container scrollable">
      <slot></slot>
    </div>
    <SideBarRight :visible="sideBarVisibility">
      <RouterView name="right" />
    </SideBarRight>
    <GS1SidePanel v-model:active="sidePanelActive" v-model:fixed="sidePanelFixed" @close="onSidePanelClose">
      <template #default>
        <RouterView name="rightPanel" />
      </template>
      <template #header>
        <RouterView name="rightPanelHeader" />
      </template>
    </GS1SidePanel>
  </main>
</template>
<script lang="ts" src="./index"></script>
<style lang="scss">
@import './index';
</style>
