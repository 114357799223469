<template>
  <div id="api-request-list" :class="classes">
    <GS1Select v-model="requestVersion" class="api-request__dropdown">
      <GS1Option v-for="(version, index) in apiVersionList" :key="index" :value="version"> Версия {{ version }} </GS1Option>
    </GS1Select>
    <GS1Search v-model="searchString" class="api-request__search" label="Поиск"></GS1Search>
    <GS1Tabs @click="closeTestPanel()">
      <GS1TabsItem
        v-for="apiRequest in apiRequestList"
        :key="apiRequest.id"
        :to="{
          name: 'gs1-api-request',
          params: {
            api: $route.params.api,
            method: apiRequest.method,
            request: apiRequest.request,
            version: requestVersion,
          },
        }"
      >
        <span class="method" :class="apiRequest.method">{{ apiRequest.method }}</span>
        {{ apiRequest.name }}
      </GS1TabsItem>
    </GS1Tabs>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'UserProfile',
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    /**
     * Строка поиска
     */
    const searchString: Ref<string> = ref('');

    /**
     * Значение темы приложения (темная/светлая)
     */
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    /**
     * Версия запросов
     */
    const requestVersion: Ref<string> = ref('1');

    /**
     * Список запросов API
     */
    const apiRequestList: ComputedRef<any> = computed(() => {
      return store.state.vbgApi.apiRequestList.filter((apiRequest: Record<string, any>) => {
        if (searchString.value && searchString.value.length > 0) {
          return apiRequest.request.includes(searchString) || apiRequest.name.toLowerCase().includes(searchString.value.toLowerCase());
        }

        return apiRequest;
      });
    });

    /**
     * Список версий запросов API
     */
    const apiVersionList = computed(() => store.state.vbgApi.apiVersionList);

    const closeTestPanel = () => router.push({ name: 'gs1-api-request' });

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'api-request-list': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    /**
     * Получение списка доступных запросов
     */
    const getApiRequestList = (skipVersionChange = false) => {
      store.dispatch('vbgApi/getApiVersionList', route.params).then(() => {
        if (!skipVersionChange) {
          if (typeof route.params.version == 'undefined') {
            requestVersion.value = apiVersionList.value[apiVersionList.value.length - 1];
          } else {
            requestVersion.value = route.params.version as string;
          }
        }
        store
          .dispatch('vbgApi/getApiRequestList', {
            api: route.params.api,
            version: requestVersion.value,
          })
          .then(() => {
            if (typeof route.params.request == 'undefined' || requestVersion.value !== route.params.version) {
              router.push({
                name: 'gs1-api-request',
                params: {
                  api: route.params.api,
                  version: requestVersion.value,
                  request: apiRequestList.value[0].request,
                  method: apiRequestList.value[0].method,
                },
              });
            }
          });
      });
    };

    onMounted(() => getApiRequestList());

    watch(
      () => requestVersion.value,
      () => {
        if (route.name == 'gs1-api-request') {
          getApiRequestList(true);
        }
      }
    );

    return {
      searchString,
      classes,
      requestVersion,
      apiRequestList,
      apiVersionList,
      getApiRequestList,
      closeTestPanel,
    };
  },
});
</script>
<style lang="scss">
.api-request-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .method {
    text-transform: uppercase;
    font-weight: 500;
  }
  .gs1-element.gs1-select {
    margin: 0 0 16px 0;
    width: 100%;
  }
  .api-request__dropdown {
    margin-left: var(--margin-x4);
    margin-bottom: var(--margin-x4);
  }

  .gs1-element.gs1-select .gs1-select__items {
    width: 190px;
  }

  .gs1-element.gs1-search {
    margin: 0 0 var(--margin-x4) 0;
    width: 100%;
  }
  .gs1-element.gs1-tabs {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
  .gs1-element.gs1-tabs-item + .gs1-element.gs1-tabs-item {
    margin-left: 0;
    margin-top: var(--margin-x3);
    border-left: 1px solid transparent;
  }
  .gs1-element.gs1-tabs-item {
    justify-content: flex-start;
    width: 100%;
    padding: var(--gs1-padding-x2) var(--gs1-padding-x3);
    border: 1px solid transparent;
    font-size: 12px;
    border-radius: var(--border-radius-x2);
    background: none;
    transition: var(--transition-super-fast-smooth);
    &.active {
      background-color: var(--color-main-23);
    }
    &:hover {
    }
    &:not(.active):hover {
      background: var(--color-main-23);
      border: 1px solid #dae6f4;
      border-radius: 8px;
    }

    .method {
      color: inherit;
    }
    .gs1-tabs-item__value {
      font-weight: 400;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: left;
    }
  }
  .api-request__search .gs1-search__input {
    width: 100% !important;
  }
  .gs1-element.gs1-option {
    height: 32px;
    min-height: 32px;
  }

  &.mode-dark {
    .gs1-element.gs1-tabs-item {
      background-color: transparent;
      border-color: transparent;
      &.active,
      &:hover {
        background-color: var(--color-main-3);
        border-color: transparent;
      }
    }
  }
}
</style>
