<template>
  <div class="component__sidebar-right" :class="classes">
    <div :class="classes" class="sidebar-right__background" :style="`width:${leftMenuWidth};`" @click="close"></div>
    <GS1BtnIcon :icon="['close']" class="close-button" no-margin gray @click="close"></GS1BtnIcon>
    <div class="component__sidebar-right__content scrollable">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed, inject } from 'vue';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';
import type EventBus from '@/models/EventBus';

export default defineComponent({
  name: 'SideBarRight',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const isVisible = computed((): boolean => props.visible);
    const isActive = computed((): boolean => !!store.state.rightSideBar);
    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        active: isActive.value,
      };
    });

    const leftMenuWidth = computed(() => (store.state.leftMenuActive ? 'calc(100vw - 220px)' : 'calc(100vw - 80px)'));

    const bus: EventBus | undefined = inject('bus');
    const close = (): void | null => (bus ? bus.emit('close-right-sidebar') : null);

    return {
      classes,
      close,
      active: isActive,
      show: isVisible,
      leftMenuWidth,
    };
  },
});
</script>
<style lang="scss">
.component__sidebar-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 0px;
  height: 100%;
  flex-shrink: 0;
  will-change: width;
  transition: width var(--transition-medium-smooth);
  position: fixed;
  right: -40px;
  background-color: var(--color-main-f);
  z-index: 2;

  &.active {
    width: 960px;
    right: 0px;
  }
  .gs1-element.gs1-btn-icon {
    border-radius: var(--border-radius-x2) 0 0 var(--border-radius-x2);
    z-index: 2;
  }

  .component__sidebar-right__content {
    width: 960px;
    height: 100%;
    padding: var(--padding-x4);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 99;
    background-color: var(--color-main-f);
  }
  .close-button {
    position: absolute;
    border-radius: var(--border-radius-x2) 0 0 var(--border-radius-x2);
    left: -32px;
    top: 24px;
  }
  .sidebar-right__background {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100vw - 220px);
    height: calc(100vh - 60px);
    z-index: 1;
    background-color: #6c757d;
    opacity: 0;
    transition: width var(--transition-medium-smooth);
    pointer-events: none;
    &.active {
      pointer-events: all;
      opacity: 0.3;
    }
  }
}
</style>
