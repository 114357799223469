<template>
  <div id="api-example-python">
    <GS1CodeViewer :value="example"></GS1CodeViewer>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef, PropType } from 'vue';
import RequestHeader from '../models/RequestHeader';

export default defineComponent({
  name: 'ExamplePython',
  props: {
    apiRequest: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array as PropType<RequestHeader[]>,
      default: () => [],
    },
    body: {
      type: String,
      default: '',
    },
    soapLogin: {
      type: String,
      default: '',
    },
    soapPassword: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * Массив непустых заголовков
     */
    const notEmptyHeaders: ComputedRef<RequestHeader[]> = computed(() => {
      return props.headers.filter((header) => {
        return header.name && header.value;
      });
    });

    const example = computed(() => {
      let example = '';

      if (typeof props.apiRequest.method !== 'undefined') {
        example +=
          `import os
from dotenv import load_dotenv
from requests import Session
from requests.auth import HTTPBasicAuth 
from zeep import Client
from zeep.transports import Transport

load_dotenv()

url = "` +
          props.apiRequest.request_example +
          `"
login = "` +
          props.soapLogin +
          `"
password = "` +
          props.soapPassword +
          `"

session = Session()
session.auth = HTTPBasicAuth(login, password)
client = Client(url, transport=Transport(session=session))
data = ` +
          props.apiRequest.json_soap_parameters +
          `

response = client.service.` +
          props.apiRequest.soap_request +
          `(*data)

print(response)`;
      }

      return example;
    });
    return {
      notEmptyHeaders,
      example,
    };
  },
});
</script>

<style lang="scss"></style>
