import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_ModalConfirm = _resolveComponent("ModalConfirm")!
  const _component_GS1ActivityConfirmation = _resolveComponent("GS1ActivityConfirmation")!
  const _component_GS1AlertBar = _resolveComponent("GS1AlertBar")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent('layout-' + _ctx.layout), null, {
    default: _withCtx(() => [
      _createVNode(_component_RouterView, { name: "default" }),
      _createVNode(_component_ModalConfirm),
      (_ctx.logoutModalShow)
        ? (_openBlock(), _createBlock(_component_GS1ActivityConfirmation, {
            key: 0,
            onContinue: _ctx.onContinue,
            onDecline: _ctx.onDecline
          }, null, 8, ["onContinue", "onDecline"]))
        : _createCommentVNode("", true),
      _createVNode(_component_GS1AlertBar, {
        ref: "alertbar",
        class: "alert-bar"
      }, null, 512)
    ]),
    _: 1
  }))
}