export default class User {
  /**
   * ID
   */
  id: number;

  /**
   * gln
   */
  gln: string | null;

  /**
   * Логин
   */
  login: string;

  /**
   * E-mail
   */
  email: string;

  /**
   * Номер телефона
   */
  phone: string;

  /**
   * Имя
   */
  name: string;

  /**
   * True, если пользователь был успешно авторизован
   */
  loggedIn: boolean;

  /**
   * Статус PRO
   */
  pro: boolean;

  /**
   * Код роли пользователя
   */
  role: number;

  /**
   * Набор доступов пользователя
   */
  access: Record<string, boolean>;

  /**
   * Токен для запросов к API
   */
  tokens: { [key: string]: Record<string, string> };

  /**
   * Наличие запросов на доступ к API
   */
  hasApiRequest: boolean;

  /**
   * Причины отклонения доступов к API
   */
  declineReasons: { [key: string]: Record<string, string> };

  /**
   * Компания пользователя
   */
  company: { [key: string]: Record<string, string> };

  /**
   * Список уведомлений, на которые подписан польователь
   */
  subscribedNotifications: Array<string>;

  /**
   * Статус членства
   */
  membershipStatus: boolean;

  /**
   * Дата окончания членства
   */
  membershipActiveTill: string;

  /**
   * Название компании
   */
  companyName: string;

  /**
   * gln компании
   */
  companyGln: string | null;

  /**
   * ИНН компании
   */
  companyInn: string;

  /**
   * ФИО
   */
  fullName: string;

  constructor() {
    this.id = 0;
    this.login = '';
    this.email = '';
    this.name = '';
    this.gln = '';
    this.phone = '';
    this.tokens = {};
    this.declineReasons = {};
    this.loggedIn = false;
    this.pro = false;
    this.role = 2;
    this.access = {};
    this.hasApiRequest = false;
    this.company = {};
    this.subscribedNotifications = [];
    this.membershipStatus = false;
    this.membershipActiveTill = '';
    this.companyName = '';
    this.companyInn = '';
    this.companyGln = '';
    this.fullName = '';
  }

  /**
   * Проверка на сущестование доступа к API
   *
   * @param api ID API
   */
  gs1ApiAccess(api: string): number {
    if (typeof this.tokens[api] != 'undefined') {
      return parseInt(this.tokens[api].access) + 2;
    }

    return 1;
  }

  /**
   * Проверка на то, что пользователь может получить уведомления
   *
   * @param data Данные уведомления
   */
  canBeNotified(data: Record<string, string | boolean | number>): boolean {
    return data.global == true || data.user_id == this.id || this.subscribedNotifications.includes(data.module + '.' + data.type);
  }
}
