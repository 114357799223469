import axios from 'axios';
import User from '@/modules/auth/models/User';

export default {
  namespaced: true,
  state() {
    return {
      user: {},
      api: {},
      srsAccess: { read: false, write: false },
      apiList: [],
      apiRequest: {},
      apiRequestList: [],
      apiVersionList: [],
      authorities: [],
    };
  },
  mutations: {
    /**
     * Устанавливает пользователя
     *
     * @param state
     * @param user Загруженный пользователь
     */
    setUser(state: Record<string, any>, user: Record<string, any>): void {
      const userObject = new User();

      userObject.id = user.id;
      userObject.login = user.login;
      userObject.email = user.email;
      userObject.role = user.role;
      userObject.tokens = user.tokens_by_api;

      state.user = userObject;
    },

    /**
     * Устанавливает список API
     *
     * @param state
     * @param user Загруженный список API
     */
    setApiList(state: Record<string, any>, apiList: Array<Record<string, any>>): void {
      state.apiList = apiList;
    },

    /**
     * Устанавливает список API
     *
     * @param state
     * @param user Загруженный список API
     */
    setApi(state: Record<string, any>, api: Record<string, any>): void {
      state.api = api;
    },

    /**
     * Устанавливает запрос
     *
     * @param state
     * @param apiRequest Загруженный запрос
     */
    setApiRequest(state: Record<string, any>, apiRequest: Record<string, any>): void {
      state.apiRequest = apiRequest;
    },

    /**
     * Устанавливает список запросов
     *
     * @param state
     * @param apiRequestList Загруженный список запросов
     */
    setApiRequestList(state: Record<string, any>, apiRequestList: Array<Record<string, any>>): void {
      state.apiRequestList = apiRequestList;
    },

    /**
     * Устанавливает список версий запросов
     *
     * @param state
     * @param apiRequestList Загруженный список запросов
     */
    setApiVersionList(state: Record<string, any>, apiVersionList: Array<string>): void {
      state.apiVersionList = apiVersionList;
    },

    /**
     * Устанавливает права доступа к API SRS
     *
     * @param state
     * @param apiRequestList Загруженный список запросов
     */
    setSrsAccess(state: Record<string, any>, srsAccess: Record<string, boolean>): void {
      state.srsAccess = srsAccess;
    },

    /**
     * Обновление примеров запросов
     *
     * @param state
     * @param data Новые примеры запросов
     */
    updateSoapExample(state: Record<string, any>, data: Record<string, boolean>): void {
      state.apiRequest.php_soap_parameters = data.php_soap_parameters;
      state.apiRequest.json_soap_parameters = data.json_soap_parameters;
    },

    /**
     * Устанавливает список полномочий
     *
     * @param state
     * @param apiRequestList Загруженный список запросов
     */
    setAuthorities(state: Record<string, any>, authorities: Record<string, string|number>[]): void {
      state.authorities = authorities;
    },
  },
  actions: {
    /**
     * Запрос на получение доступа к API
     *
     * @param context
     * @param data Код API
     */
    async requestAccess({ commit }: { commit: any }, data: Record<string, string | boolean>): Promise<any> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        const result = await axios.post('vbg-api/request-access/' + data.api + '?retry=' + (data.retry ? '1' : '0'), data.userInfo);
        return result.data;
      } catch (errors: any) {
        return errors.response.data;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Обновление токена
     *
     * @param context
     * @param api Код API
     */
    async refreshToken({ commit }: { commit: any }, api: string): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        await axios.get('vbg-api/refresh/' + api);
        return true;
      } catch (errors) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Получение пользователя
     *
     * @param context
     */
    async getUser({ commit }: { commit: any }, id: number): Promise<void> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        const response = await axios.get('users/' + id);
        commit('setUser', response.data);
      } catch (errors) {
        commit('setUser', {});
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Разрешение доступа
     *
     * @param context
     */
    async approve({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        await axios.post('vbg-api/approve/' + data.id, { api: data.api });
        return true;
      } catch (errors) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Отклонение доступа
     *
     * @param context
     */
    async decline({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        await axios.post('vbg-api/decline/' + data.id, { comment: data.comment, api: data.api });
        return true;
      } catch (errors) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Получение списка API
     *
     * @param context
     */
    async getApiList({ commit }: { commit: any }): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        const response = await axios.get('vbg-api');
        commit('setApiList', response.data);
        return true;
      } catch (errors) {
        commit('setApiList', []);
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Получение API
     *
     * @param context
     * @param api Код API
     */
    async getApi({ commit }: { commit: any }, api: string): Promise<any> {
      commit('setLoading', { flagName: 'api-info', flagState: true }, { root: true });

      try {
        const response = await axios.get('vbg-api/' + api);
        commit('setApi', response.data);
        return response.data;
      } catch (errors) {
        commit('setApi', {});
        return {};
      } finally {
        commit('setLoading', { flagName: 'api-info', flagState: false }, { root: true });
      }
    },

    /**
     * Получение информации о запросе API
     *
     * @param context
     * @param data Информация о типе запроса
     */
    async getApiRequest({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'api-request', flagState: true }, { root: true });

      if (typeof data.version !== 'undefined' && data.version == 'undefined') delete data.version;

      try {
        const response = await axios.get('vbg-api/request?' + new URLSearchParams(data).toString());
        commit('setApiRequest', response.data);
        return true;
      } catch (errors) {
        commit('setApiRequest', {});
        return false;
      } finally {
        commit('setLoading', { flagName: 'api-request', flagState: false }, { root: true });
      }
    },

    /**
     * Получение информации о запросе API по его ID
     *
     * @param context
     * @param id ID запроса
     */
    async getApiRequestById({ commit }: { commit: any }, id: number): Promise<any> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        const response = await axios.get('vbg-api/request/' + id);
        commit('setApiRequest', response.data);
        return response.data;
      } catch (errors) {
        commit('setApiRequest', {});
        return {};
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Получение списка запросов API
     *
     * @param context
     * @param api API, для которого нужно получить список
     */
    async getApiRequestList({ commit }: { commit: any }, data: Record<string, string>): Promise<any> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        let url = 'vbg-api/requests/' + data.api + (typeof data.version !== 'undefined' ? '/' + data.version : '');
        if (typeof data.all !== 'undefined' && data.all) {
          url += '?all';
        }
        const response = await axios.get(url);
        commit('setApiRequestList', response.data);
        return response.data;
      } catch (errors) {
        commit('setApiRequestList', []);
        return [];
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Получение списка запросов API
     *
     * @param context
     * @param api API, для которого нужно получить список
     */
    async getApiVersionList({ commit }: { commit: any }, data: Record<string, string>): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        const response = await axios.get('vbg-api/requests/' + data.api + '/versions');
        commit('setApiVersionList', response.data);
        return true;
      } catch (errors) {
        commit('setApiVersionList', []);
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Проверка хэша ссылки подтверждения/отклонения доступа
     *
     * @param context
     * @param data
     */
    async checkLinkHash({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        const response: Record<string, any> = await axios.get(
          'vbg-api/check-link?api=' + data.params.api + '&user=' + data.params.id + '&hash=' + data.hash
        );
        const success = response.data.success;

        return success;
      } catch (errors) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Отправка формы на доступ к API SRS
     *
     * @param context
     * @param userData
     */
    async requestSrsAccess({ commit }: { commit: any }, userData: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        await axios.post('vbg-api/request-srs-access', userData);
        return true;
      } catch (errors) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Получение уровней доступа к API SRS
     *
     * @param context
     */
    async getSrsAccess({ commit }: { commit: any }): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        const response = await axios.get('vbg-api/srs-access');
        commit('setSrsAccess', response.data);

        return true;
      } catch (errors) {
        commit('setSrsAccess', { read: false, write: false });

        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Сохранение API
     *
     * @param context
     * @param data Параметры API
     */
    async saveApi({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        await axios.post('vbg-api/' + data.code, data);

        return true;
      } catch (error) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Сохранение запроса API
     *
     * @param context
     * @param data Параметры API
     */
    async saveApiRequest({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        await axios.post('vbg-api/request/' + data.id, data);

        return true;
      } catch (error) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },

    /**
     * Обновление примера запроса
     *
     * @param context
     * @param data Параметры (body - тело запроса в XML)
     */
    async updateSoapExample({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      try {
        const response = await axios.post('vbg-api/request/' + data.id + '/update-example', data);

        commit('updateSoapExample', response.data);

        return true;
      } catch (error) {
        commit('updateSoapExample', {
          php_soap_parameters: '',
          json_soap_parameters: '',
        });

        return false;
      }
    },

    /**
     * Получение списка полномочий для API
     *
     * @param context
     * @param data Фильтр по параметрам полномочий
     */
    async getAuthorities({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'api', flagState: true }, { root: true });

      try {
        const response = await axios.get('vbg-api/authorities?' + new URLSearchParams(data).toString());
        commit('setAuthorities', response.data);
        return true;
      } catch (errors) {
        commit('setAuthorities', []);
        return false;
      } finally {
        commit('setLoading', { flagName: 'api', flagState: false }, { root: true });
      }
    },
  },
  modules: {},
};
