import dayjs from 'dayjs';

export default class Toast {
  /**
   * Время создания уведомления
   */
  timestamp: number;

  /**
   * Заголовок уведомления
   */
  title: string;

  /**
   * Сообщение, которое отображается в уведомлении
   */
  message: string;

  /**
   * Тип уведомления. Соответствует цветам bootstrap (default, primary, warning и др.)
   */
  type: string;

  constructor(title = '', message = '', type = 'default') {
    this.timestamp = dayjs().valueOf();
    this.title = title;
    this.message = message;
    this.type = type;
  }
}
