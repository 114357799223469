<template>
  <div class="slider__container">
    <div class="slider__container__slides" :style="`transform: translateX(${translateX}px);`">
      <div v-for="(slide, index) in sliders" :key="index">
        <img :src="slide.value" class="slider__img" />
      </div>
    </div>

    <button class="slider__button prev-button" @click="prevSlider">
      <GS1Icon class="prev-arrow" color="#a7a7a7" icon="arrow-left" min></GS1Icon>
    </button>

    <button class="slider__button next-button" @click="nextSlider">
      <GS1Icon class="next-arrow" color="#a7a7a7" icon="arrow-right" min></GS1Icon>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';

export default defineComponent({
  name: 'Slider',
  props: {
    img: {
      type: Array,
      default: () => [
        {
          language: 'zh-CN',
          value: '@//images/placeholder.png',
        },
      ],
    },
  },
  setup(props) {
    const sliders = computed(() => props.img);
    const translateX = ref(0);
    const slideWidth = ref(160);
    const prevSlider = () => {
      if (Math.abs(translateX.value) <= 0) {
        translateX.value = (props.img.length - 1) * slideWidth.value * -1;
      } else {
        translateX.value += slideWidth.value;
      }
    };
    const nextSlider = () => {
      if (Math.abs(translateX.value) >= (props.img.length - 1) * slideWidth.value) {
        translateX.value = 0;
      } else {
        translateX.value -= slideWidth.value;
      }
    };

    return {
      sliders,
      translateX,
      prevSlider,
      nextSlider,
    };
  },
});
</script>

<style lang="scss">
.slider__container {
  position: relative;
  display: flex;
  overflow: hidden;

  .slider__container__slides {
    display: flex;
    transition: transform var(--transition-fast-smooth);
  }

  .slider__img {
    display: flex;
    overflow: hidden;
    width: 160px;
    height: 160px;
  }
  .slider__button {
    position: absolute;
    top: 50%;

    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #bbb;
  }
  .prev-button {
    left: 0;
  }
  .prev-arrow {
    position: absolute;
    left: 1px;
    top: 2px;
  }
  .next-button {
    right: 0;
  }
  .next-arrow {
    position: absolute;
    left: 1px;
    top: 2px;
  }
}

.side-panel {
  z-index: 100;
  position: fixed;
  top: calc(4.375rem + 1px);
  right: 0;
  bottom: 0;
  width: 900px;
  max-width: 100%;
  background: #fff;
  box-shadow: 0 0px 5px rgb(0 0 0 / 25%);
  transform: translate(100%);
  transition: transform 0.3s;
  padding: 1rem;
  overflow: auto;
  &.active {
    transform: translate(0%);
  }

  .side-panel-content {
    padding: 1rem;
  }
}
</style>
