<template>
  <div v-click-outside="hideCalendar" class="date-picker">
    <i class="icon-calendar" />

    <input v-model="dateString" type="text" readonly @click="showCalendar = !showCalendar" />

    <DatePicker v-show="showCalendar" ref="datePicker" v-model="dateValue" :is-range="isRange" :first-day-of-week="2" locale="ru" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import type { PropType, Ref } from 'vue';
import { DatePicker } from 'v-calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

type DateRange = {
  start: Date;
  end: Date;
};

export default defineComponent({
  name: 'DateCalendar',
  components: {
    DatePicker,
  },
  props: {
    isRange: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Object, Date] as PropType<Date | Record<string, Date>>,
      required: true,
      defaul: () => ({
        start: new Date(),
        end: new Date(),
      }),
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const showCalendar = ref<boolean>(false);
    const datePicker: Ref<InstanceType<any> | null> = ref(null);

    watch(
      () => props.modelValue,
      (value) => {
        showCalendar.value = false;
        // Ручное обновление дат в компоненте календаря, т.к. автоматически это не происходит
        if (datePicker.value) datePicker.value.forceUpdateValue(value, { notify: false });
      }
    );
    const dateValue = computed({
      get: () => props.modelValue,
      set: (value: Date | Record<string, Date>) => emit('update:modelValue', value),
    });
    /**
     * Строка даты
     */
    const dateString = computed(() => {
      if (!props.isRange) {
        const start = dayjs(props.modelValue as Date);

        return start.date() + start.locale('ru').format('MMM') + start.year();
      } else {
        const start = dayjs((props.modelValue as DateRange)?.start);
        const end = dayjs((props.modelValue as DateRange).end);

        const startDate =
          (start.date() != end.date() || start.month() != end.month() || start.year() != end.year() ? ' ' + start.date() : '') +
          (start.month() != end.month() || start.year() != end.year() ? ' ' + start.locale('ru').format('MMM') : '') +
          (start.year() != end.year() ? ' ' + start.year() : '');

        return startDate + (startDate.length > 0 ? ' - ' : '') + end.date() + ' ' + end.locale('ru').format('MMM') + ' ' + end.year();
      }
    });
    /**
     * Скрытие календаря
     */
    const hideCalendar = (): void => {
      showCalendar.value = false;
    };

    return {
      dateValue,
      showCalendar,
      dateString,
      hideCalendar,
      datePicker,
    };
  },
});
</script>

<style lang="scss">
.date-picker {
  position: relative;
  i {
    font-size: 20px;
    position: absolute;
    left: 4px;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 20px;
    display: flex;
    align-items: center;
    color: #888b8d;
  }
  input {
    cursor: pointer;
    padding-left: 30px !important;
  }
  .vc-container {
    position: absolute;
    top: 30px;
    z-index: 10;
  }
}
</style>
