<template>
  <div class="home" />
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: {},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.commit('setH1', '');
    });
  },
});
</script>
