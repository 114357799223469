import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-default__content-container scrollable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_LeftMenu = _resolveComponent("LeftMenu")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_SideBarRight = _resolveComponent("SideBarRight")!
  const _component_GS1SidePanel = _resolveComponent("GS1SidePanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainHeader),
    _createElementVNode("main", {
      class: _normalizeClass(_ctx.classes)
    }, [
      (_ctx.showLeftMenu)
        ? (_openBlock(), _createBlock(_component_LeftMenu, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createVNode(_component_SideBarRight, { visible: _ctx.sideBarVisibility }, {
        default: _withCtx(() => [
          _createVNode(_component_RouterView, { name: "right" })
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_GS1SidePanel, {
        active: _ctx.sidePanelActive,
        "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sidePanelActive) = $event)),
        fixed: _ctx.sidePanelFixed,
        "onUpdate:fixed": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidePanelFixed) = $event)),
        onClose: _ctx.onSidePanelClose
      }, {
        default: _withCtx(() => [
          _createVNode(_component_RouterView, { name: "rightPanel" })
        ]),
        header: _withCtx(() => [
          _createVNode(_component_RouterView, { name: "rightPanelHeader" })
        ]),
        _: 1
      }, 8, ["active", "fixed", "onClose"])
    ], 2)
  ], 64))
}