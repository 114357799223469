<template>
  <div :class="{ 'left-menu__component-dark': ViewMode === 'dark', 'left-menu__component-light': ViewMode === 'light' }">
    <GS1SideBar v-model:active="active" :view-mode="ViewMode" top-offset="60px" width-open="220px" width-close="56px">
      <template #default>
        <GS1VerticalMenu>
          <GS1VerticalMenuItem
            v-for="(link, index) in menu"
            :key="'leftMenuItem' + String(index)"
            :icon="link.icon.default"
            :view-mode="ViewMode"
            :active="link.active"
            :min="!active"
            :to="link.nested && link.nested.length ? null : link.route"
          >
            {{ link.label }}
            <template v-if="link.nested.length" #menu>
              <GS1VerticalMenuSubItem
                v-for="(nestedLink, nestedIndex) in link.nested"
                :key="nestedIndex"
                :active="nestedLink.active"
                :to="nestedLink.route"
              >
                {{ nestedLink.label }}
              </GS1VerticalMenuSubItem>
            </template>
          </GS1VerticalMenuItem>
        </GS1VerticalMenu>
      </template>

      <template #footer-info>
        <div class="left-menu__footer-description">
          <b
            :class="{
              questions: true,
              'display-large': true,
              'text-margin-botton-x2': true,
              'font-black': ViewMode === 'light',
              'font-white': ViewMode === 'dark',
            }"
            >Возникли вопросы?
          </b>
          <div class="footer-description__email-block">
            <span
              :class="{
                'display-secondary': true,
                'text-margin-botton-x2': true,
                'font-gray': ViewMode === 'light',
                'font-white': ViewMode === 'dark',
              }"
              >Свяжитесь с нами:&nbsp;
            </span>
            <a href="mailto:vbg@gs1ru.org" class="footer-description__email-block__email display-secondary ubuntu"> vbg@gs1ru.org</a>
          </div>
          <router-link class="changelog-link font-wbold" :to="{ name: 'changelog' }">История версий</router-link>
        </div>
      </template>
    </GS1SideBar>
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef, WritableComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import MenuLink from '@/models/MenuLink';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'LeftMenu',
  components: {},
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading['check-access']);
    const active = computed({
      get: () => store.state.leftMenuActive,
      set: (value: string) => store.commit('setLeftMenuActive', value),
    });
    const currentYear = computed(() => dayjs().year());

    /**
     * Переключение темы со светлой на темную
     */
    const ViewMode: WritableComputedRef<string> = computed({
      get: () => store.state.ViewMode,
      set: (value: string) => store.commit('setViewMode', value),
    });

    const linkConditions: ComputedRef<Record<string, boolean>> = computed(() => {
      return {
        default: true,
        bulk: store.state.gtinSearch.activeBulkResults > 0,
      } as Record<string, boolean>;
    });

    const currentRouteName: ComputedRef<Array<string>> = computed((): Array<string> => {
      if (!route?.meta?.activeMenuRoute || !Array.isArray(route?.meta?.activeMenuRoute)) return [];
      return route?.meta?.activeMenuRoute;
    });

    const menu: ComputedRef<any> = computed(() => {
      let menu = store.getters['sortMenu'];

      menu.map((link: MenuLink): MenuLink => {
        if (typeof link.nested !== 'undefined' && link.nested.length > 0) {
          link.nested = link.nested.sort((firstChild: MenuLink, secondChild: MenuLink): number => {
            return firstChild.sort - secondChild.sort;
          });

          link.nested = link.nested.map((link: MenuLink): MenuLink => {
            link.class = { hide: !linkConditions.value[link.condition] };
            link.class['link-' + link.route.name] = true;
            link.active = currentRouteName.value.includes(link.route.name);
            return link;
          });
        }

        link.active = currentRouteName.value.includes(link.route.name);

        return link;
      });

      menu = menu.map((link: MenuLink): MenuLink => {
        link.class = { hide: !linkConditions.value[link.condition] };
        link.class['link-' + link.route.name] = true;

        return link;
      });

      return Object.assign([], menu); /* .sort((firstLink: MenuLink, secondLink: MenuLink): number => {
        return firstLink.sort - secondLink.sort;
      }); */
    });

    return { ViewMode, currentRouteName, menu, loading, active, currentYear };
  },
});
</script>
<style lang="scss">
.left-menu__container {
  width: 250px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: var(--padding-x3) var(--padding-x3);
  transition: var(--transition-fast-smooth);

  &.dark {
    background-color: var(--color-main-2);

    .left-menu__text-large {
      color: var(--color-main-f);
    }
  }

  &.light {
    background-color: var(--color-additional-8);

    .left-menu__text-large {
      color: var(--color-main-2);
    }
  }
}
.left-menu__component-dark {
  .gs1-element.gs1-vertical-menu-sub-item.mode-light:hover {
    color: var(--color-main-f);
  }
  .changelog-link {
    color: var(--color-main-4);
  }
}
.left-menu__component-light {
  .gs1-element.gs1-vertical-menu-sub-item.mode-light:hover {
    color: var(--color-main-2);
  }
}
.left-menu__bottom-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.left-menu__view-mode {
  margin-bottom: var(--margin-x6);
}

.left-menu__text-large {
  transition: var(--transition-fast-smooth);
  margin-bottom: var(--margin-x2);
}
.ubuntu {
  font-family: Ubuntu;
}
.left-menu__footer-description {
  display: flex;
  flex-direction: column;

  .display-large.questions {
    font-size: 14px;
  }

  > b {
    font-weight: 600;
  }

  .footer-description__email-block {
    font-weight: 500;
    .footer-description__email-block__email {
      text-decoration: none;
      color: var(--color-main-8);
    }
  }
}
.changelog-link {
  display: inline-block;
  color: var(--color-main-3);
  margin-top: var(--margin-x2);
  align-self: flex-start;
  &:hover {
    color: var(--color-main-8);
  }
}

@media (min-width: 1840px) {
  .left-menu__container {
    width: 300px;
  }
}
</style>
