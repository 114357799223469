import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GS1Option = _resolveComponent("GS1Option")!
  const _component_GS1Select = _resolveComponent("GS1Select")!
  const _component_GS1Search = _resolveComponent("GS1Search")!
  const _component_GS1TabsItem = _resolveComponent("GS1TabsItem")!
  const _component_GS1Tabs = _resolveComponent("GS1Tabs")!

  return (_openBlock(), _createElementBlock("div", {
    id: "api-request-list",
    class: _normalizeClass(_ctx.classes)
  }, [
    _createVNode(_component_GS1Select, {
      modelValue: _ctx.requestVersion,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.requestVersion) = $event)),
      class: "api-request__dropdown"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apiVersionList, (version, index) => {
          return (_openBlock(), _createBlock(_component_GS1Option, {
            key: index,
            value: version
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Версия " + _toDisplayString(version), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_GS1Search, {
      modelValue: _ctx.searchString,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchString) = $event)),
      class: "api-request__search",
      label: "Поиск"
    }, null, 8, ["modelValue"]),
    _createVNode(_component_GS1Tabs, {
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeTestPanel()))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apiRequestList, (apiRequest) => {
          return (_openBlock(), _createBlock(_component_GS1TabsItem, {
            key: apiRequest.id,
            to: {
          name: 'gs1-api-request',
          params: {
            api: _ctx.$route.params.api,
            method: apiRequest.method,
            request: apiRequest.request,
            version: _ctx.requestVersion,
          },
        }
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", {
                class: _normalizeClass(["method", apiRequest.method])
              }, _toDisplayString(apiRequest.method), 3),
              _createTextVNode(" " + _toDisplayString(apiRequest.name), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _: 1
    })
  ], 2))
}