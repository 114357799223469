<template>
  <div class="toasts-top-right fixed">
    <div
      v-for="(toast, index) in toasts"
      :key="index"
      class="toast fade show"
      :class="'bg-' + toast.type"
    >
      <div class="toast-header">
        <strong class="mr-auto">{{ toast.title }}</strong>
        <button
          type="button"
          class="ml-2 mb-1 close"
          @click="closeToast(toast)"
        >
          <span>×</span>
        </button>
      </div>
      <div class="toast-body">
        {{ toast.message }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';
import Toast from '@/models/Toast';

export default defineComponent({
  name: 'ToastNotification',
  setup() {
    const store = useStore();

    /**
     * Объект всплывающего уведомления
     */
    const toasts: ComputedRef<Toast> = computed(() => store.state.toasts);

    /**
     * Закрывает уведомление
     */
    const closeToast = (toast: Toast): void => {
      store.commit('closeToast', toast);
    };

    return {
      toasts,
      closeToast,
    };
  },
});
</script>
