import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "result__heading" }
const _hoisted_2 = {
  ref: "content",
  class: "result__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GS1BtnIcon = _resolveComponent("GS1BtnIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ hidden: _ctx.hidden, active: !_ctx.hidden }, "result__block_wrapper"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "title"),
      _createVNode(_component_GS1BtnIcon, {
        "no-margin": "",
        icon: ['arrow-down', 'medium'],
        onClick: _ctx.toggle
      }, null, 8, ["onClick"])
    ]),
    _createElementVNode("div", {
      class: "result__block",
      style: _normalizeStyle(`height: ${_ctx.currentHeight}px;`)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ], 512)
    ], 4)
  ], 2))
}