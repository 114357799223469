<template>
  <div id="api-example-php">
    <GS1CodeViewer :value="example"></GS1CodeViewer>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef, PropType } from 'vue';
import RequestHeader from '../models/RequestHeader';

export default defineComponent({
  name: 'ExamplePHP',
  props: {
    apiRequest: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array as PropType<RequestHeader[]>,
      default: () => [],
    },
    body: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * Массив непустых заголовков
     */
    const notEmptyHeaders: ComputedRef<RequestHeader[]> = computed(() => {
      return props.headers.filter((header) => {
        return header.name && header.value;
      });
    });

    const example: ComputedRef<any> = computed(() => {
      let example = '';

      if (typeof props.apiRequest.method !== 'undefined') {
        example +=
          `<?php

// Параметры запроса
$options = [
    'http' => [
        // Метод запроса
        'method' => '` +
          props.apiRequest.method.toUpperCase() +
          `',
        // Заголовки запроса в виде строки
        'header' =>\n`;

        notEmptyHeaders.value.forEach((header: RequestHeader, index: number) => {
          example += '            "' + header.name + ': ' + header.value + '\\r\\n"';
          example += index == notEmptyHeaders.value.length - 1 ? ',\n' : '.\n';
        });
        if (props.apiRequest.method == 'post') {
          example +=
            `        // Тело запроса
        'content' => '` +
            props.body +
            `'`;
        }
        example +=
          `]];

// Создание контекста
$context = stream_context_create($options);
// Выполнение запроса
$result = file_get_contents('` +
          props.apiRequest.request_example +
          `', false, $context);

// Вывод ответа запроса на экран
print_r($result);`;
      }

      return example;
    });

    return {
      notEmptyHeaders,
      example,
    };
  },
});
</script>

<style lang="scss"></style>
