import { Store } from 'vuex';
import store from './store';
import pusher from './pusher';
import Notifications from './views/Notifications.vue';

export default class GtinSearchModule {
  router: Record<string, any>;
  store: Store<any>;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
  }

  init() {
    this.addRoutes();
    this.store.registerModule('notifications', store);
    pusher.subscribe();
  }

  addRoutes(): void {
    this.router.addRoute({ path: '/notifications', component: Notifications, name: 'notifications' });
  }
}
