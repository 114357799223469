<template>
  <h2 class="display-large">Запроса доступа к API</h2>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ApiAccessHeader',
});
</script>

<style lang="scss" scoped>
h2.display-large {
  margin: 0;
  font-size: 14px;
}
</style>
