import pusher from '@/pusher';
import store from '@/store';

export default {
  subscribe() {
    pusher.on('open', () => {
      pusher.subscribe('GtinSearch');

      pusher.onEvent('StatusUpdate', function (data: Record<string, any>) {
        if (store.state.auth.user.id == data.user_id) {
          store.dispatch('gtinSearch/getActiveBulkResults');

          store.state.gtinSearch.bulkSearchList.active.data.forEach((item: Record<string, any>, index: number) => {
            if (item.id == data.id) {
              // Обновление полей записи
              Object.keys(item).forEach(function (key) {
                if (typeof data[key] !== 'undefined') {
                  item[key] = data[key];
                }
              });

              item.class = {};
              item.class['status-' + item.status_color] = true;
              item.class.hiding = false;

              // Перемещение записи в список завершённых
              if ([5, 6, 7].includes(data.status)) {
                store.state.gtinSearch.bulkSearchList.active.data.splice(index, 1);
                item.showDownload = false;
                store.state.gtinSearch.bulkSearchList.complete.data.unshift(item);
              }
            }
          });
        }
      });
    });
  },
};
