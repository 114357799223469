import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "component__sidebar-right__content scrollable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GS1BtnIcon = _resolveComponent("GS1BtnIcon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["component__sidebar-right", _ctx.classes])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.classes, "sidebar-right__background"]),
      style: _normalizeStyle(`width:${_ctx.leftMenuWidth};`),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
    }, null, 6),
    _createVNode(_component_GS1BtnIcon, {
      icon: ['close'],
      class: "close-button",
      "no-margin": "",
      gray: "",
      onClick: _ctx.close
    }, null, 8, ["onClick"]),
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}