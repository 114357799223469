import { Store } from 'vuex';
import store from './store';
import pusher from './pusher';
import ApiPage from './views/ApiPage.vue';
import ApiRequest from './views/ApiRequest.vue';
import MenuLink from '@/models/MenuLink';
import ApiAccess from './components/ApiAccess.vue';
import ApiAccessHeader from './components/ApiAccessHeader.vue';
import ApiRequestTest from './components/ApiRequestTest.vue';
import ApiRequestTestHeader from './components/ApiRequestTestHeader.vue';

export default class VbgApiModule {
  router: Record<string, any>;
  store: Store<any>;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
  }

  init() {
    this.addRoutes();
    this.addLinks();
    this.store.registerModule('vbgApi', store);
    pusher.subscribe();
  }

  addRoutes(): void {
    this.router.addRoute({
      path: '/gs1-api',
      component: ApiPage,
      name: 'gs1-api',
      meta: { activeMenuRoute: ['gs1-api'], fixedRightPanel: false },
    });
    this.router.addRoute({
      path: '/gs1-api/:api',
      component: ApiRequest,
      name: 'gs1-api-request-index',
      meta: { activeMenuRoute: ['gs1-api', 'access-api-docs'], fixedRightPanel: true },
    });
    this.router.addRoute({
      path: '/gs1-api/:api/:method/:request/:version',
      component: ApiRequest,
      name: 'gs1-api-request',
      meta: { activeMenuRoute: ['gs1-api', 'access-api-docs'], fixedRightPanel: false },
    });
    this.router.addRoute({
      path: '/gs1-api/:api/:method/:request/:version/test',
      components: { default: ApiRequest, rightPanel: ApiRequestTest, rightPanelHeader: ApiRequestTestHeader },
      name: 'gs1-api-request-test',
      meta: {
        parent: 'gs1-api',
        nested: true,
        fixedRightPanel: false,
        activeMenuRoute: ['gs1-api', 'gs1-api-request-test'],
      },
    });
    this.router.addRoute({
      path: '/gs1-api/access/:api',
      components: { default: ApiPage, rightPanel: ApiAccess, rightPanelHeader: ApiAccessHeader },
      name: 'gs1-api-access',
      meta: { activeMenuRoute: ['gs1-api', 'gs1-api-access'], fixedRightPanel: false },
    });
  }

  addLinks(): void {
    this.store.dispatch('addLink', new MenuLink('API', { default: 'code', light: 'code-l-h', dark: 'code-d-h' }, 10, { name: 'gs1-api' }));
  }
}
