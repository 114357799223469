import axios from 'axios';
import dayjs from 'dayjs';

export default {
  namespaced: true,
  state() {
    return {
      /**
       * Список всплывающих уведомлений
       */
      activeNotifications: [],

      /**
       * Список последних уведомлений
       */
      lastNotifications: [],

      /**
       * Кол-во новых уведомлений
       */
      newNotifications: 0,

      /**
       * Список уведомлений
       */
      notifications: {
        data: [],
      },

      /**
       * Фильтр последних уведомлений
       */
      lastNotificationsFilter: {
        showType: 'all',
      },

      /**
       * Фильтр уведомлений
       */
      notificationsFilter: {
        date: {
          start: dayjs().subtract(1, 'week').hour(0).minute(0).toDate(),
          end: dayjs().hour(23).minute(59).toDate(),
        },
        module: '',
        status: '',
      },
    };
  },
  mutations: {
    /**
     * Добавляет уведомление в список всплывающих уведомлений
     * @param state
     * @param notification
     */
    addNotification(state: Record<string, any>, notification: Record<string, string | number>) {
      state.activeNotifications.push(notification);

      if (typeof notification.onlyActive == 'undefined' || !notification.onlyActive) {
        if (state.lastNotificationsFilter.showType != 'read') {
          state.lastNotifications.unshift(notification.object);

          if (state.lastNotifications.length > 5) {
            state.lastNotifications.pop();
          }
        }
        state.notifications.data.unshift(notification.object);
        state.newNotifications++;
      }

      setTimeout(() => {
        state.activeNotifications = state.activeNotifications.filter(
          (activeNotification: Record<string, string | number>) => notification.id != activeNotification.id
        );
      }, 5000);
    },

    /**
     * Добавляет уведомление в список всплывающих уведомлений
     * @param state
     * @param id ID уведомления, которое нужно скрыть
     */
    closeNotification(state: Record<string, any>, id: string) {
      state.activeNotifications = state.activeNotifications.filter((notification: Record<string, string | number>) => notification.id != id);
    },

    /**
     * Устанавливает список последних уведомлений
     * @param state
     * @param id ID уведомления, которое нужно скрыть
     */
    setLastNotifications(state: Record<string, any>, notifications: Array<Record<string, string | number>>) {
      state.lastNotifications = notifications;
    },

    /**
     * Обновление кол-ва новых уведомлений
     * @param state
     * @param amount
     */
    setNewNotifications(state: Record<string, any>, amount: number) {
      state.newNotifications = amount;
    },

    /**
     * Устанавливает список уведомлений
     * @param state
     * @param id ID уведомления, которое нужно скрыть
     */
    setNotifications(state: Record<string, any>, notifications: Array<Record<string, string | number>>) {
      state.notifications = notifications;
    },

    /**
     * Удаление уведомления
     * @param state
     * @param id ID уведомления, которое нужно скрыть
     */
    removeNotification(state: Record<string, any>, notificationId: number) {
      state.notifications.data = state.notifications.data.filter(
        (notification: Record<string, string | number>) => notification.id != notificationId
      );
    },

    /**
     * Сброс фильтра уведомлений
     *
     * @param state
     */
    resetNotificationsFilter(state: Record<string, any>) {
      state.notificationsFilter = {
        date: {
          start: dayjs().subtract(1, 'week').hour(0).minute(0).toDate(),
          end: dayjs().hour(23).minute(59).toDate(),
        },
        module: '',
        status: '',
      };
    },
  },
  actions: {
    /**
     * Получение последних уведомлений пользователя
     *
     * @param context
     * @param data Параметры запроса. type - тип уведомлений (read - прочитанные, unread - не прочитанные)
     */
    async getLastNotifications({ commit, state }: { commit: Function; state: any }): Promise<void> {
      commit('setLoading', { flagName: 'notifications', flagState: true }, { root: true });

      try {
        commit('setLastNotifications', []);
      } catch (errors) {
        commit('setLastNotifications', []);
      } finally {
        commit('setLoading', { flagName: 'notifications', flagState: false }, { root: true });
      }
    },

    /**
     * Получение кол-ва новых уведомлений пользователя
     *
     * @param context
     */
    async getNewNotifications({ commit }: { commit: Function }): Promise<void> {
      try {
        commit('setNewNotifications', 0);
      } catch (errors) {
        commit('setNewNotifications', 0);
      }
    },

    /**
     * Изменение состояния "прочитано" уведомления
     *
     * @param context
     * @param data Параметры запроса. notificationId - ID уведомления, state - статус (true - прочитано, false - не прочитано)
     */
    async toggleRead(context: any, data: Record<string, boolean | number>): Promise<boolean> {
      try {
        await axios.post('notifications/' + data.notificationId + '/toggle-read', {
          state: data.state,
        });

        return true;
      } catch (errors) {
        return false;
      }
    },

    /**
     * Удаление уведомления
     *
     * @param context
     * @param notificationId ID уведомления
     */
    async remove({ commit }: { commit: Function }, notificationId: number): Promise<boolean> {
      commit('removeNotification', notificationId);

      try {
        await axios.delete('notifications/' + notificationId);

        return true;
      } catch (errors) {
        return false;
      }
    },

    /**
     * Получение уведомлений пользователя
     *
     * @param context
     * @param data Параметры запроса
     */
    async getNotifications({ commit, state }: { commit: Function; state: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'notifications', flagState: true }, { root: true });

      try {
        const query = new URLSearchParams({
          dateStart: dayjs(state.notificationsFilter.date.start).format('YYYY-MM-DD HH:mm:ss'),
          dateEnd: dayjs(state.notificationsFilter.date.end).format('YYYY-MM-DD HH:mm:ss'),
          module: state.notificationsFilter.module,
          status: state.notificationsFilter.status,
          page: typeof data.route.query.page !== 'undefined' ? data.route.query.page : 1,
        }).toString();
        commit('setNotifications', []);
      } catch (errors) {
        commit('setNotifications', []);
      } finally {
        commit('setLoading', { flagName: 'notifications', flagState: false }, { root: true });
      }
    },
  },
};
