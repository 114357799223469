import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type AxiosError from 'axios';
import Toast from '@/models/Toast';
import { saveAs } from 'file-saver';

export default {
  namespaced: true,
  state() {
    return {
      /**
       * Объект найденых компаний
       */
      companies: [],

      /**
       * Объект компании
       */
      company: {},

      /**
       * Список стран для поиска по названию
       */
      countries: [],

      /**
       * Кол-во результатов поиска
       */
      companyCount: 0,

      /**
       * Кол-во филиалов поиска по ИНН
       */
      branchCount: 0,

      /**
       * Лимиты запросов
       */
      limits: {
        maxSearchValues: 50,
        requestCooldown: 3,
      },
    };
  },
  mutations: {
    /**
     * Устанавливает найденные компании
     *
     * @param state
     * @param companies Найденные компании
     */
    setCompanies(state: Record<string, any>, companies: Array<Record<string, any>>): void {
      state.companies = companies;
    },

    /**
     * Устанавливает кол-во филиалов поиска по ИНН
     *
     * @param state
     * @param branchCount
     */
    setBranchCount(state: Record<string, any>, branchCount: number): void {
      state.branchCount = branchCount;
    },

    /**
     * Устанавливает найденную команию
     *
     * @param state
     * @param gtin Найденная компания
     */
    setCompany(state: Record<string, any>, company: Record<string, any>): void {
      state.company = company;
    },

    /**
     * Устанавливает список стран
     *
     * @param state
     * @param countries Список стран
     */
    setCountries(state: Record<string, any>, countries: Array<Record<string, any>>): void {
      state.countries = countries;
    },

    /**
     * Устанавливает кол-во результатов
     *
     * @param state
     * @param countries Список стран
     */
    setCompanyCount(state: Record<string, any>, companyCount: number): void {
      state.companyCount = companyCount;
    },

    /**
     * Устанавливает лимиты запросов
     *
     * @param state
     * @param showGtinResult
     */
    setLimits(state: Record<string, any>, limits: Record<string, string>): void {
      state.limits.maxSearchValues = limits.max_values;
      state.limits.requestCooldown = limits.request_cooldown;
    },
  },
  actions: {
    /**
     * Запрос для поиска компаний по ключам
     *
     * @param context
     * @param data Объект с данными запроса
     */
    async searchCompanies(
      { dispatch, commit }: { dispatch: any; commit: any },
      data: Record<string, any>
    ): Promise<Record<string, string | boolean>> {
      commit('setLoading', { flagName: 'company', flagState: true }, { root: true });

      let url = '';
      let requestData;
      switch (data.searchKey) {
        case 'inn':
          url = 'company-search/by-inn';
          requestData = {
            inns: data.searchValues,
            show_branches: data.showBranches,
          };
          break;
        case 'gln':
          url = 'company-search/by-gln';
          requestData = data.searchValues;
          break;
        default:
          url = 'company-search/by-key/' + data.searchKey;
          requestData = data.searchValues;
          break;
      }

      try {
        const response = await axios.post(url, requestData);

        if (data.searchKey == 'inn') {
          const companiesWithIndex = response.data.data.map((company: any, index: any) => ({
            ...company,
            index: index.toString(),
          }));
          commit('setCompanies', companiesWithIndex);
          commit('setBranchCount', response.data.branchCount);
        } else {
          commit('setCompanies', response.data);
        }

        return { success: true };
      } catch (error: InstanceType<typeof AxiosError>) {
        commit('setCompanies', []);
        switch (error.response.status) {
          case 401:
            dispatch('showToast', new Toast('Ошибка', 'Ошибка подключения. Попробуйте позже.', 'danger'), { root: true });
            break;
          case 500:
            dispatch('showToast', new Toast('Ошибка', 'Неизвестная ошибка. Попробуйте позже.', 'danger'), { root: true });
            break;
        }

        return error.response.data;
      } finally {
        commit('setLoading', { flagName: 'company', flagState: false }, { root: true });
      }
    },

    /**
     * Запрос для поиска компаний по названию
     *
     * @param context
     * @param data Объект с данными запроса
     */
    async searchCompaniesByName(
      { dispatch, commit }: { dispatch: any; commit: any },
      data: Record<string, any>
    ): Promise<Record<string, string | boolean>> {
      commit('setLoading', { flagName: 'company', flagState: true }, { root: true });

      try {
        const response: Record<string, any> = await axios.post('company-search/by-name', {
          name: data.name,
          country: data.country,
          postal: data.postal,
          city: data.city,
          address: data.address,
        });
        commit(
          'setCompanies',
          response.data.licences.map((company: any, index: any) => ({
            ...company,
            index: index.toString(),
          }))
        ),
          commit('setCompanyCount', response.data.totalRecords);

        return { success: true };
      } catch (error: InstanceType<typeof AxiosError>) {
        commit('setCompanies', []);
        switch (error.response.status) {
          case 401:
            dispatch('showToast', new Toast('Ошибка', 'Ошибка подключения. Попробуйте позже.', 'danger'), { root: true });
            break;
          case 500:
            dispatch('showToast', new Toast('Ошибка', 'Неизвестная ошибка. Попробуйте позже.', 'danger'), { root: true });
            break;
        }

        return {
          success: false,
        };
      } finally {
        commit('setLoading', { flagName: 'company', flagState: false }, { root: true });
      }
    },

    /**
     * Экспорт результатов поиска в CSV
     *
     * @param context
     * @param data
     */
    async export({ dispatch, commit }: { dispatch: any; commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'company', flagState: true }, { root: true });

      try {
        const response: any = await axios.post('company-search/export/' + data.searchKey + '/csv', { values: data.searchValues });

        const binary = Uint8Array.from(atob(response.data.file), (c) => c.charCodeAt(0));
        const blob = new Blob([binary], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, 'export.csv');

        return true;
      } catch (error: InstanceType<typeof AxiosError>) {
        switch (error.response.status) {
          case 401:
            dispatch('showToast', new Toast('Ошибка', 'Ошибка подключения. Попробуйте позже.', 'danger'), { root: true });
            break;
        }

        return true;
      } finally {
        commit('setLoading', { flagName: 'company', flagState: false }, { root: true });
      }
    },

    /**
     * Получение списка стран
     *
     * @param context
     */
    async getCountries({ dispatch, commit }: { dispatch: any; commit: any }): Promise<Record<string, string | boolean>> {
      try {
        const response = await axios.get('company-search/countries');
        commit('setCountries', response.data);

        return { success: true };
      } catch (error: InstanceType<typeof AxiosError>) {
        commit('setCountries', []);
        switch (error.response.status) {
          case 401:
            dispatch('showToast', new Toast('Ошибка', 'Ошибка подключения. Попробуйте позже.', 'danger'), { root: true });
            break;
          case 500:
            dispatch('showToast', new Toast('Ошибка', 'Неизвестная ошибка. Попробуйте позже.', 'danger'), { root: true });
            break;
        }

        return {
          success: false,
        };
      }
    },

    /**
     * Запрос лимитов
     *
     * @param context
     */
    async getLimits({ commit }: { commit: any }): Promise<void> {
      try {
        const response = await axios.get('company-search/limits');
        commit('setLimits', response.data);
      } catch (error) {
        commit('setLimits', {});
      }
    },
  },
};
