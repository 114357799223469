import axios from 'axios';
import dayjs from 'dayjs';

export default {
  namespaced: true,
  state() {
    return {
      /**
       * Список результатов быстрого поиска (в шапке)
       */
      quickSearchResults: [],

      /**
       * Список результатов поиска
       */
      searchResults: [],

      /**
       * Список модулей для поиска
       */
      searchModules: {},

      /**
       * Строка поиска в шапке
       */
      navbarSearchString: ''
    };
  },
  mutations: {
    /**
     * Устанавливает результаты поиска
     * 
     * @param state 
     * @param data Результаты поиска
     * @param quick Установить результат быстрого поиска (в шапке)
     */
    setSearchResults(state: Record<string, any>, data: Record<string, any>) {
      if (data.quick == '1') {
        state.quickSearchResults = data.searchResults;
      } else {
        // Если выполнялся только запрос для отдельного модуля при изменении страницы, то происходит замена результата только для этого модуля
        if (data.pageChange == '1') {
          state.searchResults.forEach((searchResult: Record<string, any>, key: number) => {
            if (searchResult.code == data.searchResults[0].code) {
              state.searchResults[key] = data.searchResults[0];
            }
          });
        } else {
          state.searchResults = data.searchResults;
        }
      }
    }, 

    /**
     * Устанавливает список модулей для поиска
     * 
     * @param state 
     * @param searchModules Список модулей
     */
     setSearchModules(state: Record<string, any>, searchModules: Record<string, string>) {
      state.searchModules = searchModules;
    },

    /**
     * Устанавливает строку поиска в шапке
     * 
     * @param state 
     * @param newString Новое значение
     */
    setNabvarSearchString(state: Record<string, any>, newString: string) {
      state.navbarSearchString = newString;
    }
  },
  actions: {
    /**
     * Поиск
     * 
     * @param context
     * @param data Параметры запроса (search - поисковая строка)
     */
    async search({ commit }: { commit: Function }, data: Record<string, any>): Promise<void>  {
      commit('setLoading', { flagName: 'search', flagState: true }, { root: true });

      const quick = typeof data.quick !== 'undefined' && data.quick ? '1' : '0';
      const pageChange = typeof data.pageChange !== 'undefined' && data.pageChange ? '1' : '0';

      try {
        const query = new URLSearchParams();
        query.append('search', data.search);
        query.append('page', data.page);
        query.append('quick', quick);

        if (typeof data.date !== 'undefined') {
          if (typeof data.date.start !== 'undefined') {
            query.append('dateStart', dayjs(data.date.start).format('YYYY-MM-DD HH:mm:ss'));
          }
          if (typeof data.date.end !== 'undefined') {
            query.append('dateEnd', dayjs(data.date.end).format('YYYY-MM-DD HH:mm:ss'));
          }
        }

        if (typeof data.modules !== 'undefined') {
          data.modules.forEach((module: string) => {
            query.append('modules[]', module);
          });
        }

        const response = await axios.get('search?' + query.toString());
        commit('setSearchResults', {
          searchResults: response.data,
          quick: quick,
          pageChange: pageChange
        });
      } catch (errors) {
        commit('setSearchResults', {
          searchResults: [],
          quick: quick,
          pageChange: pageChange
        });
      } finally {
        commit('setLoading', { flagName: 'search', flagState: false }, { root: true });
      }
    },

    /**
     * Список модулей для поиска
     * 
     * @param context
     */
    async modules({ commit }: { commit: Function }): Promise<void>  {
      commit('setLoading', { flagName: 'search', flagState: true }, { root: true });

      try {
        const response = await axios.get('search/modules');
        commit('setSearchModules', response.data);
      } catch (errors) {
        commit('setSearchModules', {});
      } finally {
        commit('setLoading', { flagName: 'search', flagState: false }, { root: true });
      }
    }
  }
};
