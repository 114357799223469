import pusher from '@/pusher';
import store from '@/store';
import router from '@/router';

export default {
  subscribe() {
    pusher.on('open', () => {
      pusher.subscribe('Notifications');

      pusher.onEvent('NewNotification', function (data: Record<string, any>) {
        if (store.state.auth.user.canBeNotified(data)) {
          store.commit('notifications/addNotification', {
            title: data.title,
            message: data.message,
            url: data.url,
            id: data.id,
            object: data.object,
          });
        }
      });

      pusher.onEvent('SetRead', function (data: Record<string, any>) {
        if (store.state.auth.user.id == data.user_id) {
          store.dispatch('notifications/getNewNotifications');
          store.dispatch('notifications/getNotifications', { route: router.currentRoute.value });
        }
      });

      pusher.onEvent('Removed', function (data: Record<string, any>) {
        if (store.state.auth.user.id == data.user_id) {
          store.dispatch('notifications/getNewNotifications');
        }
      });
    });
  },
};
