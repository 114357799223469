export default {
  endpoint: process.env.VUE_APP_ENDPOINT,
  oauthLogin: process.env.VUE_APP_OAUTH_LOGIN,
  oauthPassword: process.env.VUE_APP_OAUTH_PASSWORD,
  oauthFormUrl: process.env.VUE_APP_OAUTH_FORM_URL,
  oauthRedirectUrl: process.env.VUE_APP_OAUTH_REDIRECT_URL,
  pusherToken: process.env.VUE_APP_PUSHER_TOKEN,
  pusherUrl: process.env.VUE_APP_PUSHER_URL,
  gs1adminEndpoint: process.env.VUE_APP_GS1ADMIN_ENDPOINT,
  appEnv: process.env.VUE_APP_ENV,
  appUrl: process.env.VUE_APP_URL,
  appLogoutUrl: process.env.VUE_APP_LOGOUT_URL,
  appInactiveUserUrl: process.env.VUE_APP_INACTIVE_USER_URL,
  vueAppOauthErrorUrl: process.env.VUE_APP_OAUTH_ERROR_URL,
  maxFileSize: process.env.VUE_APP_MAX_FILE_SIZE,
  lkEndpoint: process.env.VUE_APP_LK_ENDPOINT,
};
