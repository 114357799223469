<template>
  <div class="outer-container">
    <div id="search">
      <h1>Поиск</h1>

      <ListFilter
        v-model:date="date"
        v-model:searchString="searchString"
        class="search-filter"
        show-date-range
        show-calendar
        show-search
        date-range-type="radio"
        @reset="resetFilter"
        @search="search"
      >
        <template #after>
          <SelectInput
            v-model="searchInModules"
            :options="searchModules"
            class="multiple search-modules-select"
            :multiple-label="searchModulesLabel"
            :close-on-select="false"
            placeholder="Все разделы"
            mode="multiple"
            @select="search"
            @deselect="search"
          />
        </template>
      </ListFilter>

      <div class="list">
        <div v-for="searchResult in searchResults" :key="searchResult.code" class="search-result" :class="{ active: searchResult.active }">
          <div class="heading">
            <div class="title" @click="toggleResults(searchResult)">
              {{ searchResult.title }}
            </div>

            <div class="right">
              <Pagination
                ref="paginations"
                :total-pages="searchResult.last_page"
                :total-results="searchResult.total"
                @change="search($event, searchResult.code)"
              />

              <i v-if="searchResult.total > 0" class="fas fa-angle-down toggle" @click="toggleResults(searchResult)" />
            </div>
          </div>

          <div class="items">
            <router-link v-for="(item, key) in searchResult.items" :key="key" class="item" :to="item.url" v-html="item.title" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref } from 'vue';
import type { ComputedRef, Ref, Component } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import User from '@/modules/auth/models/User';
import Pagination from '@/components/Pagination.vue';
import SelectInput from '@/components/SelectInput.vue';
import ListFilter from '@/components/ListFilter.vue';
import dayjs from 'dayjs';

export default defineComponent({
  name: 'Search',
  components: {
    ListFilter,
    SelectInput,
    Pagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    /**
     * Промежуток дат
     */
    const dateRange: Ref<string> = ref('week');

    /**
     * Поисковая строка
     */
    const searchString: Ref<string> = ref('');

    /**
     * Список модулей, в которых нужно производить поиск
     */
    const searchInModules: Ref<any> = ref([]);

    /**
     * Фильтр по дате
     */
    const date: Ref<any> = ref({
      start: dayjs().subtract(1, 'week').hour(0).minute(0).toDate(),
      end: dayjs().hour(23).minute(59).toDate(),
    });

    const paginations: Ref<Array<Component>> = ref([]);

    /**
     * Объект авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    /**
     * Резултат поиска
     */
    const searchResults: ComputedRef<Array<Record<string, any>>> = computed(() => {
      return store.state.search.searchResults.map((searchResult: Record<string, any>) => {
        searchResult.active = true;

        return searchResult;
      });
    });

    /**
     * Модули для поиска
     */
    const searchModules: ComputedRef<Array<Record<string, string>>> = computed(() => {
      const searchModules = [];

      for (const value in store.state.search.searchModules) {
        searchModules.push({
          value: value,
          label: store.state.search.searchModules[value],
        });
      }

      return searchModules;
    });

    /**
     * Поиск
     */
    const search = (page = 1, moduleCode = ''): void => {
      if (typeof page !== 'number') page = 1;
      if (typeof moduleCode !== 'string') moduleCode = '';

      let modules: any = searchInModules.value;
      const pageChange = moduleCode.length > 0;
      if (pageChange) {
        modules = [moduleCode];
      }

      router.push({ query: { search: searchString.value } });

      store
        .dispatch('search/search', {
          search: searchString.value,
          modules: modules,
          date: date.value,
          page: page,
          pageChange: pageChange,
        })
        .then(() => {
          // Если запрос выполнялся для общего поиска, а не для изменения страницы, то происходит сброс всех постраничных навигаций
          if (!pageChange) {
            /* searchResults.value.forEach((searchResult: Record<string, any>) => {
              if (refs['pagination' + searchResult.code]) {
                (
                  refs['pagination' + searchResult.code] as InstanceType<
                    typeof Pagination
                  >
                ).resetPage();
              }
            }); */
            paginations.value.map((ref: Component) => (ref as InstanceType<typeof Pagination>).resetPage());
          }
        });
    };

    /**
     * Сброс фильтра
     */
    const resetFilter = (): void => {
      searchInModules.value = [];
      searchString.value = '';
      store.commit('search/setNabvarSearchString', '');
      search();
    };

    /**
     * Заголовок поля выбора модулей для поиска
     */
    const searchModulesLabel = (): string => {
      const amount = searchInModules.value.length;
      switch (amount % 10) {
        case 1:
          return 'Выбран 1 раздел';
        case 2:
        case 3:
        case 4:
          return 'Выбрано ' + amount + ' раздела';
        default:
          return 'Выбрано ' + amount + ' разделов';
      }
    };

    /**
     * Раскрытие списка результатов модуля
     */
    const toggleResults = (result: Record<string, any>): void => {
      result.active = !result.active;
    };

    onMounted(() => {
      store.dispatch('search/modules').then(() => {
        search();
      });
    });

    return {
      dateRange,
      searchString,
      searchInModules,
      date,
      user,
      searchResults,
      searchModules,
      paginations,
      search,
      resetFilter,
      searchModulesLabel,
      toggleResults,
    };
  },
});
</script>

<style lang="scss">
#search {
  max-width: 100%;
  .search-result {
    .heading {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #888b8d;
      .title {
        color: #002c6c;
        font-size: 15px;
        cursor: pointer;
        flex: 1 0 auto;
      }
      .right {
        display: flex;
        .pages {
          margin-right: 40px;
        }
        .toggle {
          font-size: 18px;
          cursor: pointer;
          color: #002c6c;
          transition: transform 0.3s;
          height: 18px;
        }
      }
    }
    .items {
      display: flex;
      flex-direction: column;
      max-height: 0;
      transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
      overflow: hidden;
      .item {
        margin-top: 20px;
        color: #888b8d;
        .highlight {
          color: #002c6c;
        }
      }
    }

    &.active {
      .toggle {
        transform: rotate(180deg);
      }
      .items {
        max-height: 3000px !important;
        transition: max-height 1s ease-in-out;
      }
    }
    + .search-result {
      margin-top: 35px;
    }
  }

  .search-modules-select .multiselect-dropdown {
    width: 320px;
  }
}
</style>
