import { computed, getCurrentInstance } from 'vue';
import ViewModes from '../models/ViewMode';
import SideBars from '@/models/SideBars';
import { useStore } from 'vuex';

export default function useLayoutHelpers() {
  const store = useStore();
  const context = getCurrentInstance();
  // @ts-expect-error ctx нет в доке
  const ViewMode = computed((): string => context?.ctx?.$root?.ViewMode);

  /**
   * Определяет тип представления
   * Проверяет тип сначала в localStorage, потом у пользователя в ОС
   * @returns {ViewModes} - тип представления
   */
  const resolveViewMode = () => {
    let viewMode: string | null = localStorage.getItem('view-mode');

    if (!viewMode && typeof window !== 'undefined' && window?.matchMedia && window?.matchMedia('(prefers-color-scheme: dark)').matches)
      viewMode = ViewModes.dark;

    if (!viewMode) viewMode = ViewModes.dark;

    return viewMode;
  };

  const openSideBar = (name: SideBars = SideBars.right) => store.commit('openSideBar', name);
  const closeSideBar = (name: SideBars = SideBars.right) => store.commit('closeSideBar', name);

  return { ViewMode, resolveViewMode, openSideBar, closeSideBar };
}
