import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type AxiosError from 'axios';
import Toast from '@/models/Toast';
import { saveAs } from 'file-saver';

export default {
  namespaced: true,
  state() {
    return {
      /**
       * Объект найденного GTIN
       */
      gtins: [],

      /**
       * Выбранный Gtin
       */
      gtin: {},

      /**
       * Флаг говорящий о том, был ли найден GTIN
       */
      foundGtin: false,
      /**
       * Флаг отображения результата
       */
      showGtinResult: false,
      /**
       * Лимиты запросов
       */
      limits: {
        maxGtins: 50,
        requestCooldown: 3,
      },

      /**
       * Список файлов для масового поиска
       */
      bulkSearchList: {
        active: { data: [] },
        complete: { data: [] },
      },

      /**
       * Кол-во активных массовых поисков
       */
      activeBulkResults: '0',

      /**
       * Кол-во не завершенных массовых поисков
       */
      unfinishedBulkResults: '0',

      /**
       * Кол-во успешных массовых поисков
       */
      successBulkResults: '0',

      /**
       * Кол-во массовых поисков с ошибками
       */
      errorBulkResults: '0',

      /**
       * Общее кол-во массовых поисков
       */
      totalBulkResults: '0',
    };
  },
  getters: {
    getAllBulks(state: Record<string, any>) {
      const allBulks = [];
      return allBulks.push(state.bulkSearchList.active, state.bulkSearchList.complete);
    },
  },
  mutations: {
    /**
     * Устанавливает найденный GTIN
     *
     * @param state
     * @param gtins Найденные GTIN
     */
    setGtins(state: Record<string, any>, gtins: Array<Record<string, any>>): void {
      state.gtins = gtins;
    },

    /**
     * Устанавливает найденный GTIN
     *
     * @param state
     * @param gtin Найденные GTIN
     */
    setGtin(state: Record<string, any>, gtin: any): void {
      state.gtin = gtin;
    },

    /**
     * Устанавливает флаг foundGtin
     *
     * @param state
     * @param gtin Найденный GTIN
     */
    setFoundGtin(state: Record<string, any>, foundGtin: boolean): void {
      state.foundGtin = foundGtin;
    },

    /**
     * Устанавливает флаг отображения результата
     *
     * @param state
     * @param showGtinResult
     */
    setShowGtinResult(state: Record<string, any>, showGtinResult: boolean): void {
      state.showGtinResult = showGtinResult;
    },

    /**
     * Устанавливает лимиты запросов
     *
     * @param state
     * @param showGtinResult
     */
    setLimits(state: Record<string, any>, limits: Record<string, string>): void {
      state.limits.maxGtins = limits.max_gtins;
      state.limits.requestCooldown = limits.request_cooldown;
    },

    /**
     * Устанавливает список файлов для масового поиска
     *
     * @param state
     * @param data Список файлов
     */
    setBulkSearchList(state: Record<string, any>, data: Record<string, any>): void {
      if (data.append && state.bulkSearchList[data.code].data.length > 0) {
        state.bulkSearchList[data.code].data = state.bulkSearchList[data.code].data.concat(data.items.data);
      } else {
        state.bulkSearchList[data.code] = data.items;
      }
    },

    /**
     * Устанавливает список файлов для масового поиска
     *
     * @param state
     * @param data Список файлов
     */
    addBulkSearchItem(state: Record<string, any>, item: Record<string, any>): void {
      state.bulkSearchList.active.data.unshift(item);
    },

    /**
     * Устанавливает кол-во текущих активных массовых поисков
     *
     * @param state
     * @param amount Список файлов
     */
    setActiveBulkResults(state: Record<string, any>, amounts: Record<string, string>): void {
      state.activeBulkResults = amounts.active;
      state.unfinishedBulkResults = amounts.errors;
      state.successBulkResults = amounts.complete_success;
      state.errorBulkResults = amounts.complete_errors;
      state.totalBulkResults = amounts.total;
    },

    /**
     * Обновление прогресса загрузки файла
     *
     * @param state
     * @param data Параметры прогресса (timestamp - временная метка файла, percent - процент загрузки)
     */
    updateProgress(state: Record<string, any>, data: Record<string, string | number>): void {
      const item = state.bulkSearchList.active.data.find((item: Record<string, string | number>) => item.id == data.timestamp);

      if (item) {
        item.percent = data.percent;
      }
    },

    /**
     * Устанавливает статус "Загружено" и id файла
     *
     * @param state
     * @param data Параметры (id - новый id файла, timestamp - временная метка файла, statusName - статус, createdAt - время загрузки)
     */
    setLoaded(state: Record<string, any>, data: Record<string, string | number>): void {
      const item = state.bulkSearchList.active.data.find(
        (item: Record<string, string | number>) => item.id == data.timestamp && item.file_name == data.fileName
      );

      if (item) {
        item.id = data.id;
        item.status = 1;
        item.status_name = data.statusName;
        item.status_color = data.statusColor;
        item.percent = 0;
        item.created_at_formatted = data.createdAt;
        item.class = {};
        item.class['status-' + item.status_color] = true;
        item.class.hiding = false;
      }
    },
  },
  actions: {
    /**
     * Запрос для поиска по нескольким номерам GTIN
     *
     * @param context
     * @param gtinNumbers Список номеров GTIN в виде массива
     */
    async searchGtins({ dispatch, commit }: { dispatch: any; commit: any }, gtinNumbers: Array<string>): Promise<Record<string, string | boolean>> {
      commit('setLoading', { flagName: 'gtin', flagState: true }, { root: true });

      try {
        const response = await axios.post('gtin-search', gtinNumbers);
        commit('setGtins', response.data);
        commit('setFoundGtin', true);
        commit('setShowGtinResult', true);

        return { success: true };
      } catch (error: InstanceType<typeof AxiosError>) {
        commit('setGtins', []);
        commit('setFoundGtin', false);
        switch (error.response.status) {
          case 401:
            dispatch('showToast', new Toast('Ошибка', 'Ошибка подключения. Попробуйте позже.', 'danger'), { root: true });
            commit('setShowGtinResult', false);
            break;
        }

        return {
          success: false,
          status: error.response.status,
          header: error.response.data.message_header,
          code: error.response.data.error_code,
          message: error.response.data.message,
        };
      } finally {
        commit('setLoading', { flagName: 'gtin', flagState: false }, { root: true });
      }
    },

    /**
     * Экспорт результатов поиска в CSV
     *
     * @param context
     * @param data
     */
    async export({ dispatch, commit }: { dispatch: any; commit: any }, data: Record<string, string>): Promise<boolean> {
      commit('setLoading', { flagName: 'gtin', flagState: true }, { root: true });

      try {
        const response = await axios.post('gtin-search/export/' + data.format, data);

        let mime = '';
        switch (data.format) {
          case 'xlsx':
            mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            break;
          case 'csv':
            mime = 'text/csv';
            break;
        }

        const binary = Uint8Array.from(atob(response.data.file), (c) => c.charCodeAt(0));
        const blob = new Blob([binary], { type: mime + ';charset=utf-8' });
        saveAs(blob, 'export.' + data.format);

        return true;
      } catch (error: InstanceType<typeof AxiosError>) {
        switch (error.response.status) {
          case 401:
            dispatch('showToast', new Toast('Ошибка', 'Ошибка подключения. Попробуйте позже.', 'danger'), { root: true });
            commit('setShowGtinResult', false);
            break;
        }

        return true;
      } finally {
        commit('setLoading', { flagName: 'gtin', flagState: false }, { root: true });
      }
    },

    /**
     * Запрос лимитов
     *
     * @param context
     * @param gtinNumbers Список номеров GTIN в виде массива
     */
    async getLimits({ commit }: { commit: any }): Promise<void> {
      try {
        const response = await axios.get('gtin-search/limits');
        commit('setLimits', response.data);
      } catch (error) {
        commit('setLimits', {});
      }
    },

    /**
     * Загрузка списка GTIN для массового поиска
     *
     * @param context
     * @param data Объект параметров для загрузки файла
     */
    async uploadBulkSearchFile({ commit }: { commit: any }, data: Record<string, any>): Promise<Record<string, any>> {
      try {
        const formData = new FormData();
        formData.append(data.input, data.file, data.file.name);

        const response = await axios.post('gtin-search/upload-bulk-search', formData, {
          onUploadProgress: (progressEvent: any) => {
            const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            commit('updateProgress', {
              timestamp: data.timestamp,
              percent: percent,
            });
          },
        });

        return { response: response.data, success: true };
      } catch (error) {
        return { response: {}, success: false };
      }
    },

    /**
     * Получение списка файлов для массового поиска
     *
     * @param context
     * @param data Параметры запроса
     *
     * @param context
     */
    async getBulkSearchList({ commit }: { commit: any }, data: Record<string, any>): Promise<void> {
      commit('setLoading', { flagName: 'gtin-bulk', flagState: true }, { root: true });

      try {
        const query = new URLSearchParams({
          page: data.page,
          perPage: data.perPage,
        });
        data.statuses.forEach((status: any) => {
          query.append('statuses[]', status);
        });
        const response = await axios.get('gtin-search/bulk-search-list?' + query);

        commit('setBulkSearchList', {
          items: response.data,
          code: data.code,
          append: data.append || false,
        });
      } catch (error) {
        commit('setBulkSearchList', {
          items: [],
          code: data.code,
          append: false,
        });
      } finally {
        commit('setLoading', { flagName: 'gtin-bulk', flagState: false }, { root: true });
      }
    },

    /**
     * Отмена обработки списка GTIN
     *
     * @param context
     */
    async cancelBulkSearch({ commit }: { commit: any }, resultId: number): Promise<boolean> {
      commit('setLoading', { flagName: 'gtin-bulk', flagState: true }, { root: true });

      try {
        await axios.post('gtin-search/cancel-bulk-search', {
          resultId: resultId,
        });

        return true;
      } catch (error) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'gtin-bulk', flagState: false }, { root: true });
      }
    },

    /**
     * Получение кол-ва текущих активных массовых поисков
     *
     * @param context
     */
    async getActiveBulkResults({ commit }: { commit: any }): Promise<void> {
      try {
        const response = await axios.get('gtin-search/bulk-amount');
        commit('setActiveBulkResults', response.data);
      } catch (error) {
        commit('setActiveBulkResults', {
          active: 0,
          error: 0,
          total: 0,
        });
      }
    },

    /**
     * Конвертация tiff изображений
     *
     * @param context
     */
    async convertTiff(_context: any, data: Record<string, string>): Promise<string> {
      try {
        const query = new URLSearchParams({
          image_url: data.imageUrl,
          gtin: data.gtinNumber,
          index: data.index,
        });

        const response = await axios.get('gtin-search/convert-tiff?' + query);

        return response.data['image_url'];
      } catch (error) {
        return '/images/placeholder.png';
      }
    },
  },
  modules: {},
};
