<template>
  <div class="select-input">
    <Multiselect
      v-model="value"
      :multiple-label="multipleLabel"
      :hide-selected="false"
      :close-on-select="closeOnSelect"
      :placeholder="placeholder"
      :mode="mode"
      :can-deselect="false"
      :options="options"
      @select="select"
      @deselect="deselect"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  name: 'SelectInput',
  components: {
    Multiselect,
  },
  props: {
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'single',
    },
    options: {
      type: Array,
      default: () => [],
    },
    multipleLabel: {
      type: Function,
      default: () => '',
    },
    modelValue: {
      type: [Array, String, Number],
      default: () => '',
    },
  },
  emits: ['search', 'reset', 'update:date', 'update:searchString', 'select', 'update:modelValue', 'deselect'],
  setup(props, { emit }) {
    const value = computed({
      get: () => props.modelValue,
      set: (value: any) => emit('update:modelValue', value),
    });
    const select = (value: any) => {
      emit('select', value);
    };
    const deselect = (value: any) => {
      emit('deselect', value);
    };
    return {
      value,
      deselect,
      select,
    };
  },
});
</script>

<style lang="scss">
.select-input {
  position: relative;
  z-index: 1;
  .multiselect-single-label,
  .multiselect-multiple-label,
  .multiselect-placeholder {
    appearance: none;
    height: 30px;
    padding: 0 10px;
    border: 1px solid #b1b3b3;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    align-items: center;
    outline: none;
    color: #f26334;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    padding-right: 30px;
    background: url(@/assets/images/select-arrow.png) right 8px center no-repeat;
  }
  .multiselect-dropdown {
    position: absolute;
    top: calc(100% - 2px);
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    width: 100%;
    background: #fff;
    border: 1px solid #b1b3b3;
    border-top: 0;
    border-radius: 0 0 3px 3px;
    ul {
      display: block;
      color: #888b8d;
      margin: 0;
      padding: 0;
      li {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background 0.3s;
        &:hover {
          background-color: #f4f4f4;
        }
      }
    }
    &.is-hidden {
      display: none;
    }
  }
  &.multiple {
    .multiselect-dropdown {
      top: calc(100% + 4px);
      border-radius: 3px;
      border-top: 1px solid #b1b3b3;
    }
    .multiselect-option {
      position: relative;
      &::before {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid #b1b3b3;
        display: inline-block;
        vertical-align: middle;
        content: '';
        margin-right: 16px;
      }
      &.is-selected {
        font-weight: bold;
        color: #f26334;
        &::after {
          content: '\f00c';
          color: #f26334;
          font-family: 'Font Awesome 5 Free';
          font-size: 10px;
          top: 6px;
          left: 14px;
          position: absolute;
        }
      }
    }
  }
}
</style>
