<template>
  <div v-if="showPopup && user.loggedIn" id="session-popup">
    <div class="message">
      Ваша сессия была завершена. Для продолжения работы необходимо снова
      авторизоваться.
      <a class="btn btn-primary" @click.prevent="login"> Войти </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import type { Ref, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import User from '@/modules/auth/models/User';

export default defineComponent({
  name: 'SessionPopup',
  props: {
    date: {
      type: Object,
      default: () => ({}),
    },
    showDateRange: {
      type: Boolean,
      default: false,
    },
    showCalendar: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    dateRangeType: {
      type: String,
      default: 'select',
    },
    searchString: {
      type: String,
      default: '',
    },
  },
  emits: [
    'search',
    'reset',
    'update:date',
    'update:searchString',
    'login-click',
  ],
  setup(props, { emit }) {
    /**
     * Таймер
     */
    const timer: Ref<number> = ref(0);
    /**
     * Флаг отображения всплывающего окна
     */
    const showPopup: Ref<boolean> = ref(false);
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    /**
     * Объект с данными авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);
    /**
     * Обновление таймера
     *
     * @param number timeout
     */
    const refreshTimer = (timeout: number) => {
      clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        showPopup.value = true;
      }, timeout * 60 * 1000);
    };
    /**
     * Клик по кнопкек "Войти"
     */
    const login = (): void => {
      showPopup.value = false;
      const userRole = user.value.role;

      store.dispatch('auth/logout');

      if (userRole == 1) {
        router.push({ name: 'login' });
      } else {
        emit('login-click');
        router.push({ name: 'home' });
      }
    };

    watch(
      () => route.name,
      () => refreshTimer(store.state.sessionLifetime)
    );
    watch(
      () => store.state.sessionLifetime,
      () => refreshTimer(store.state.sessionLifetime)
    );
    return {
      user,
      showPopup,
      login,
    };
  },
});
</script>

<style lang="scss">
#session-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10000;
  .message {
    position: absolute;
    padding: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    a {
      margin-top: 10px;
    }
  }
}
</style>
