import type { App, Plugin } from 'vue';

const components: { [key: string]: any } | null = {};
const files = require.context('.', true, /Layout*/);
files.keys().forEach((key) => {
  const moduleName = key.match(/\.\/(.+?)\//);
  if (moduleName && moduleName[1])
    components[moduleName[1]] = files(key).default;
});

const Layouts: Plugin = {
  install(app: App) {
    if (components)
      Object.keys(components).map((name) =>
        app.component(name, components[name])
      );
  },
};

export default Layouts;
