<template>
  <div class="outer-container">
    <div id="notifications">
      <h1>Сообщения</h1>

      <div class="unread-amount">
        <template v-if="newNotifications != 1"> {{ newNotifications }} непрочитанных сообщения </template>
        <template v-if="newNotifications == 1"> 1 непрочитанное сообщение </template>
      </div>

      <ListFilter
        v-model:date="filter.date"
        class="notification-filter"
        show-date-range
        show-calendar
        date-range-type="select"
        @reset="resetFilter"
      >
        <template #after>
          <SelectInput
            v-model="filter.status"
            class="status-select"
            :options="[
              { value: '', label: 'Все' },
              { value: 'read', label: 'Прочитанные' },
              { value: 'unread', label: 'Не прочитанные' },
              { value: 'auto', label: 'Автоматические' },
              { value: 'user', label: 'От пользователя' },
            ]"
          />

          <SelectInput
            v-model="filter.module"
            class="module-select"
            :options="[
              { value: '', label: 'Все сервисы' },
              { value: 'VbgApi', label: 'API' },
            ]"
          />
        </template>
      </ListFilter>

      <div class="pages-right">
        <Pagination
          :set-query="true"
          :total-pages="notifications.last_page"
          :total-results="notifications.total"
          @change="getNotifications"
        />
      </div>

      <div class="list">
        <div v-for="notification in notifications.data" :key="notification.id" :class="{ read: notification.read }" class="notification">
          <div class="heading">
            <div class="title">
              {{ notification.title }}
            </div>

            <div class="right">
              <div class="date">
                {{ notification.date }}
              </div>

              <div class="buttons">
                <a class="toggle-read" href="#" @click.prevent="toggleRead(notification)">
                  <i class="fas fa-check" />
                </a>

                <a class="remove" href="#" @click.prevent="remove(notification.id)">
                  <i class="icon-trash" />
                </a>

                <router-link class="open" :to="notification.url">
                  <i class="fas fa-arrow-right" />
                </router-link>
              </div>
            </div>
          </div>

          <div class="info">
            <ShortText height="54" @expanded="toggleRead(notification, true)">
              <div class="message">
                {{ notification.message }}
              </div>
            </ShortText>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, watch } from 'vue';
import type { ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import User from '@/modules/auth/models/User';
import ShortText from '@/components/ShortText.vue';
import Pagination from '@/components/Pagination.vue';
import SelectInput from '@/components/SelectInput.vue';
import ListFilter from '@/components/ListFilter.vue';

export default defineComponent({
  name: 'Notifications',
  components: {
    ShortText,
    SelectInput,
    Pagination,
    ListFilter,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const filter: ComputedRef<Record<string, string>> = computed(() => store.state.notifications.notificationsFilter);
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    /**
     * Кол-во новых уведомлений
     */
    const newNotifications: ComputedRef<number> = computed(() => store.state.notifications.newNotifications);

    /**
     * Массив уведомлений
     */
    const notifications: ComputedRef<any> = computed(() => store.state.notifications.notifications);

    /**
     * Получение списка уведомлений
     */
    const getNotifications = (): void => {
      store.dispatch('notifications/getNotifications', { route: route });
    };

    /**
     * Установка статуса "прочитано"
     */
    const toggleRead = (notification: Record<string, string | number | boolean>, state: boolean): void => {
      if (typeof state == 'undefined') state = !notification.read;

      notification.read = state;

      store.dispatch('notifications/toggleRead', {
        state: state,
        notificationId: notification.id,
      });
    };

    /**
     * Удаление уведомления
     */
    const remove = (notificationId: number): void => {
      store.dispatch('notifications/remove', notificationId);
    };

    /**
     * Сброс фильтра
     */
    const resetFilter = (): void => {
      store.commit('notifications/resetNotificationsFilter');
    };

    onMounted(() => {
      store.commit('setH1', '');
      store.dispatch('auth/getUser').then(() => {
        if (user.value.loggedIn) {
          getNotifications();
          store.dispatch('notifications/getNewNotifications');
        }
      });
    });
    watch(
      () => filter,
      () => {
        router.push({ query: { page: 1 } }).then(() => {
          getNotifications();
        });
      }
    );

    return {
      filter,
      newNotifications,
      notifications,
      toggleRead,
      remove,
      resetFilter,
      getNotifications,
    };
  },
});
</script>

<style lang="scss">
#notifications {
  width: 860px;
  max-width: 100%;
  .unread-amount {
    font-size: 22px;
    margin: 0px 0 30px;
    color: #002c6c;
  }
  .list {
    .notification {
      font-weight: 400;
      margin-bottom: 16px;
      border-bottom: 1px solid #d8d8d8;
      &.read {
        .heading .title {
          color: #71b790;
        }
        .info {
          .message {
            color: #71b790;
          }
        }
        .buttons .toggle-read {
          color: #71b790;
        }
      }
      .heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right {
          display: flex;
        }
        .title {
          font-size: 17px;
          color: #002c6c;
        }
        .date {
          font-size: 12px;
          margin-right: 22px;
          color: #888b8d;
          font-style: italic;
        }
        .buttons {
          display: flex;
          align-items: center;
          a {
            color: #454545;
            &:hover {
              text-decoration: none;
            }
            + a {
              margin-left: 12px;
            }
          }
          .toggle-read,
          .open {
            height: 16px;
            width: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid;
            font-size: 10px;
            border-radius: 50%;
          }
          .remove {
            display: flex;
            align-items: center;
            font-size: 17px;
            height: 16px;
            &:hover {
              color: #f23434;
            }
          }
        }
      }
      .info {
        margin-bottom: 8px;
      }
      .message {
        font-size: 15px;
        margin-top: 6px;
        color: #002c6c;
      }
    }
  }
  .open-all {
    display: flex;
    justify-content: center;
    a {
      color: #002c6c;
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
    }
  }

  .pages-right {
    display: flex;
    justify-content: flex-end;
  }
  .date-select {
    width: 100px;
  }
  .status-select {
    width: 150px;
  }
  .module-select {
    width: 130px;
  }
}
</style>
