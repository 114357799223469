<template>
  <div id="api-example-javascript">
    <GS1CodeViewer :value="example"></GS1CodeViewer>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef, PropType } from 'vue';
import RequestHeader from '../models/RequestHeader';

export default defineComponent({
  name: 'ExampleJavaScript',
  props: {
    apiRequest: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array as PropType<RequestHeader[]>,
      default: () => [],
    },
    body: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const example: ComputedRef<any> = computed(() => {
      let example = '';

      if (typeof props.apiRequest.method !== 'undefined') {
        example +=
          `// Объект для работы с HTTP запросами
const http = new XMLHttpRequest();
// URL запроса
const url = '` +
          props.apiRequest.request_example +
          `';` +
          (props.apiRequest.method == 'post'
            ? `// Тело запроса
const body = \`` +
              props.body +
              `\`;`
            : ``) +
          `\n// Инициализация запроса
http.open('` +
          props.apiRequest.method.toUpperCase() +
          `', url, true);\n
// Заголовки запроса\n`;

        props.headers.forEach((header) => {
          if (header.value && header.name) example += "http.setRequestHeader('" + header.name + "', '" + header.value + "');\n";
        });

        example +=
          `\n// Функция, которая выполняется при завершении запроса
http.onreadystatechange = () => {
  // Проверка успешности завершения запроса
  if (http.readyState == 4 && http.status == 200) {
    // Вывода ответа запроса в консоль
    console.log(http.responseText);
  }
}

// Отправка запроса
http.send(` +
          (props.apiRequest.method == 'post' ? `body` : ``) +
          `);`;
      }

      return example;
    });
    return {
      example,
    };
  },
});
</script>

<style lang="scss"></style>
