import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "component__modal-confirm" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "component__modal-confirm__btn-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GS1Btn = _resolveComponent("GS1Btn")!
  const _component_GS1Modal = _resolveComponent("GS1Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GS1Modal, {
      active: _ctx.active,
      "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.active) = $event)),
      "hide-close-btn": ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("h4", { innerHTML: _ctx.text }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_GS1Btn, {
            min: "",
            accent: "",
            "no-margin": "",
            onClick: _ctx.onCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_GS1Btn, {
            min: "",
            success: "",
            "no-margin": "",
            onClick: _ctx.onConfirm
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirmText), 1)
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}