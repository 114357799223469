<template>
  <div id="api-example-python">
    <GS1CodeViewer :value="example"></GS1CodeViewer>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import { defineComponent, computed } from 'vue';
import type { ComputedRef, PropType } from 'vue';
import RequestHeader from '../models/RequestHeader';

export default defineComponent({
  name: 'ExamplePython',
  props: {
    apiRequest: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array as PropType<RequestHeader[]>,
      default: () => [],
    },
    body: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * Массив непустых заголовков
     */
    const notEmptyHeaders: ComputedRef<RequestHeader[]> = computed(() => {
      return props.headers.filter((header) => {
        return header.name && header.value;
      });
    });

    const example = computed(() => {
      let example = '';

      if (typeof props.apiRequest.method !== 'undefined') {
        example +=
          `# Импорт библиотеки для работы с HTTP запросами
import requests

# Настройка окружения
URL = "` +
          props.apiRequest.request_example +
          `"

# Настройка параметров для инициализации запроса
params = {
}

headers = {\n`;

        notEmptyHeaders.value.forEach((header: RequestHeader, index: number) => {
          example += '    "' + header.name + '": "' + header.value + '"';
          example += index != notEmptyHeaders.value.length - 1 ? ',\n' : '\n';
        });

        example +=
          `}` +
          (props.apiRequest.method == 'post'
            ? `
body = ` +
              props.body +
              `
`
            : ``) +
          `
# Выполнение запроса
request = requests.` +
          props.apiRequest.method +
          `(URL, ` +
          (props.apiRequest.method == 'post' ? `json=body, ` : ``) +
          `headers=headers)

# Преобразование в json
r = request.json()
# Вывод результата на терминал
print(r)`;
      }

      return example;
    });
    return {
      notEmptyHeaders,
      example,
    };
  },
});
</script>

<style lang="scss"></style>
