export function debounce(func: (...args: any[]) => void, delay: number) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error ts error
    let timeoutId: NodeJS.Timeout | undefined;

    return function (...args: any[]) {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
}