<template>
  <div id="api-example-php">
    <GS1CodeViewer :value="example"></GS1CodeViewer>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef, PropType } from 'vue';
import RequestHeader from '../models/RequestHeader';

export default defineComponent({
  name: 'ExamplePHP',
  props: {
    apiRequest: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array as PropType<RequestHeader[]>,
      default: () => [],
    },
    body: {
      type: String,
      default: '',
    },
    soapLogin: {
      type: String,
      default: '',
    },
    soapPassword: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * Массив непустых заголовков
     */
    const notEmptyHeaders = computed<RequestHeader[]>(() => {
      return props.headers.filter((header) => {
        return header.name && header.value;
      });
    });

    const example: ComputedRef<any> = computed(() => {
      let example = '';

      if (typeof props.apiRequest.method !== 'undefined') {
        example +=
          `<?php

// Инициализация SOAP клиента
$soapWsdl = '` +
          props.apiRequest.soap_wsdl +
          `';
$soapLogin = '` +
          props.soapLogin +
          `';
$soapPassword = '` +
          props.soapPassword +
          `';
$soapClient = new \\SoapClient($soapWsdl, [
    'login' => $soapLogin, 
    'password' => $soapPassword,
    'connection_timeout' => 15,
    'trace' => 1,
    'soap_version'=> SOAP_1_1
]);
// Параметры запроса
$data = ` +
          props.apiRequest.php_soap_parameters +
          `;

// Выполнение запроса
$result = $soapClient->` +
          props.apiRequest.soap_request +
          `($data);

// Вывод ответа запроса на экран
print_r($result);`;
      }

      return example;
    });

    return {
      notEmptyHeaders,
      example,
    };
  },
});
</script>

<style lang="scss"></style>
