import { createStore } from 'vuex';
import axios from 'axios';
import MenuLink from '@/models/MenuLink';
import Toast from '@/models/Toast';
import router from '@/router';
import ViewModes from '@/models/ViewMode';
import type SideBars from '@/models/SideBars';
export type VuexMainState = {
  ViewMode: ViewModes;
};

export default createStore({
  state() {
    return {
      ViewMode: ViewModes.dark,
      leftSideBar: false,
      rightSideBar: false,
      /**
       * Ссылки меню
       */
      menu: [],

      leftMenuActive: true,

      /**
       * Флаг отображения боковой панели
       */
      sidebarVisible: true,

      /**
       * Массив уведомлений
       */
      toasts: [],

      /**
       * Флаги загрузки
       */
      loading: {},

      /**
       * Заголовок страницы
       */
      h1: '',

      /**
       * Срок жизни сессии
       */
      sessionLifetime: 9999,

      /**
       * Флаг скрытия отступов у контента
       */
      noPadding: false,

      /**
       * Отображать оверлей
       */
      showOverlay: false,

      /**
       * Функция, вывзываемя при клике по оверлею
       */
      overlayCallback: () => {
        return;
      },

      /**
       * Все ссылки меню
       */
      allLinks: [],

      /**
       * Активность правой панели
       */
      sidePanelActive: false,

      /**
       * Список должностей из глобального модуля GS1
       */
      positions: [],
    };
  },
  getters: {
    sortMenu(state: any) {
      return state.menu.sort((firstLink: MenuLink, secondLink: MenuLink): number => {
        return firstLink.sort - secondLink.sort;
      });
    },
  },
  mutations: {
    /**
     * Открывает указанный сайтбар
     * @param {VuexRootState} state - Текущее состояние
     * @param {SideBars} type - Тип сайдбара
     */
    openSideBar(state: any, type: SideBars) {
      state[`${type}SideBar`] = true;
    },
    setMode(state: any, mode: ViewModes) {
      state.ViewMode = mode;
      localStorage.setItem('view-mode', state.ViewMode);
    },
    /**
     * Закрывает указанный сайтбар
     * @param {VuexRootState} state - Текущее состояние
     * @param {SideBars} type - Тип сайдбара
     */
    closeSideBar(state: any, type: SideBars) {
      state[`${type}SideBar`] = false;
    },

    /**
     * Открывает правую панель
     * @param {VuexRootState} state - Текущее состояние
     */
    openSidePanel(state: any) {
      state['sidePanelActive'] = true;
    },
    /**
     * Закрывает правую панель
     * @param {VuexRootState} state - Текущее состояние
     */
    closeSidePanel(state: any) {
      state['sidePanelActive'] = false;
    },
    /**
     * Устанавливает состояние правой панели
     * @param {VuexRootState} state - Текущее состояние
     * @param {boolean} active - Флаг состояния
     */
    setSidePanel(state: any, active: boolean) {
      state['sidePanelActive'] = active;
    },

    setLeftMenuActive(state: Record<string, any>, active: boolean): void {
      state.leftMenuActive = active;
    },

    /**
     * Добавляет ссылку в массив ссылок меню
     *
     * @param state
     * @param data
     */
    addLink(state: Record<string, any>, data: Record<string, any>): void {
      const link = data.link;
      const linkRoute = data.linkRoute;
      if (typeof linkRoute !== 'undefined' && linkRoute.meta.parent) {
        state.menu.map((addedLink: MenuLink): void => {
          if (addedLink.route.name == linkRoute.meta.parent) {
            if (typeof linkRoute.meta.nested !== 'undefined' && linkRoute.meta.nested) {
              addedLink.nested.push(link);
            } else {
              addedLink.children.push(link);
            }
          }
        });
      } else {
        state.menu.push(link);
      }
    },
    setViewMode(state: any, value: ViewModes) {
      state.ViewMode = value;
    },

    /**
     * Отображение всплывающего уведомления
     *
     * @param state
     * @param toastToClose Объект уведомления
     */
    closeToast(state: Record<string, any>, toastToClose: Toast): void {
      state.toasts = state.toasts.filter((toast: Toast) => {
        return toast.timestamp !== toastToClose.timestamp;
      });
    },

    /**
     * Устанавливает флаг loading
     *
     * @param state
     * @param flagName Флаг, который нужно изменить
     * @param flagState Состояние флага
     */
    setLoading(state: Record<string, any>, { flagName, flagState }: { flagName: string; flagState: boolean }): void {
      state.loading[flagName] = flagState;
    },

    /**
     * Устанавливает заголовок страницы
     *
     * @param state
     * @param newH1 Новый заголовок
     */
    setH1(state: Record<string, any>, newH1: string): void {
      state.h1 = newH1;
    },

    /**
     * Скрывать отступы вокруг основного контента
     *
     * @param state
     * @param noPadding
     */
    setNoPadding(state: Record<string, any>, noPadding: boolean): void {
      state.noPadding = noPadding;
    },

    /**
     * Отобразить/скрыть оверлей
     *
     * @param state
     * @param showOverlay
     */
    setOverlay(state: Record<string, any>, showOverlay: boolean): void {
      state.showOverlay = showOverlay;
    },

    /**
     * Отобразить/скрыть оверлей
     *
     * @param state
     * @param callback
     */
    setOverlayCallback(state: Record<string, any>, callback: Function): void {
      state.overlayCallback = callback;
    },

    /**
     * Очистка меню
     *
     * @param state
     */
    clearMenu(state: Record<string, any>): void {
      state.menu = [];
    },

    /**
     * Устанавливает список должностей
     *
     * @param state
     */
    setPositions(state: Record<string, any>, positions: Record<string, string | number>[]): void {
      state.positions = positions;
    },
  },
  actions: {
    /**
     * Отображение всплывающего уведомления
     *
     * @param state
     * @param toast Объект уведомления
     */
    showToast({ commit }, toast: Toast): void {
      commit('notifications/addNotification', {
        title: toast.title,
        message: toast.message,
        url: '',
        id: toast.timestamp,
        onlyActive: true,
        type: toast.type,
      });
    },

    /**
     * Проверяет доступ пользователя и добавляет ссылку в массив ссылок меню
     *
     * @param state
     * @param link
     */
    async addLink({ commit, dispatch, state }, link: MenuLink): Promise<void> {
      const routes = router.getRoutes();

      // Поиск роута, который соответсвует ссылке
      const linkRoute = routes.find((route) => {
        return route.name == link.route.name;
      });

      state.allLinks.push(link);
      commit('addLink', { link: link, linkRoute: linkRoute });
    },

    /**
     * Обновление ссылок в меню в соответствии с доступами авторизованного пользователя
     */
    async refreshMenu({ commit, dispatch, state }): Promise<void> {
      commit('clearMenu');

      state.allLinks.forEach((link: MenuLink) => {
        dispatch('addLink', link);
      });
    },

    /**
     * Получение списка должностей
     *
     * @param context
     */
    async getPositions({ commit }: { commit: any }): Promise<boolean> {
      try {
        const response = await axios.get('gs1/positions');
        commit('setPositions', response.data);
        return true;
      } catch (errors) {
        commit('setPositions', []);
        return false;
      }
    },

    init({ commit }: any) {
      let viewMode: string | null = localStorage.getItem('view-mode');
      if (!viewMode) viewMode = 'light';
      commit('setViewMode', viewMode);
    },
  },
  modules: {},
});
