<template>
  <div id="changelog" :class="classes">
    <h1 class="display-medium">История версий</h1>
    <div class="changelogs">
      <div v-for="(release, index) in releases" :key="index" class="release">
        <div class="heading">
          <GS1Icon icon="time"></GS1Icon>
          <div class="version display-large font-wbold">Версия {{ release.version }}</div>
          <div class="date font-gray display-secondary">{{ release.date }}</div>
        </div>
        <div v-if="release.description && release.description.length > 0" class="description display-large font-gray">{{ release.description }}</div>
        <ul class="changes font-gray display-bold">
          <li v-for="(change, index2) in release.changelogs" :key="index2">{{ change.description }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Changelog',
  setup() {
    const store = useStore();

    const releases: ComputedRef<Record<string, any>[]> = computed(() => store.state.changelog.releases);
    const viewMode: ComputedRef<string> = computed(() => store.state.ViewMode);
    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        ['mode-' + viewMode.value]: true,
      };
    });

    onMounted(() => {
      store.dispatch('changelog/getChangelogs');
    });

    return { classes, releases };
  },
});
</script>

<style lang="scss">
#changelog {
  h1 {
    margin-bottom: var(--margin-x4);
  }

  .release {
    padding-bottom: var(--padding-x3);
    position: relative;

    &:first-child {
      .heading .gs1-icon .gs1-icon__icon {
        background-color: var(--color-main-7) !important;
      }
    }
    &:last-child::before {
      display: none;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      height: calc(100% - 20px);
      top: 20px;
      left: 7px;
      background: var(--color-main-10);
    }

    .heading {
      display: flex;
      align-items: center;
      margin-bottom: var(--margin-x3);

      .gs1-icon {
        margin-right: var(--margin-x3);

        .gs1-icon__icon {
          background-color: var(--color-main-9);
        }
      }

      .version {
        margin-right: var(--margin-x2);
      }

      .date {
        height: 8px;
        line-height: 8px;
        border-left: 1px solid var(--color-additional-13);
        padding-left: var(--padding-x2);
      }
    }

    .description {
      margin-left: var(--margin-x5);
      margin-bottom: var(--margin-x3);
    }

    ul {
      padding-left: var(--padding-x65);
      margin: 0;
      li + li {
        margin-top: var(--margin-x1);
      }
    }
  }

  &.mode-dark {
    ul li,
    .heading .version,
    .release .description {
      color: var(--color-main-f);
    }
    .release {
      &::before {
        background: var(--color-main-22);
      }
      .heading {
        .date {
          border-color: var(--color-main-22);
          color: var(--color-main-4);
        }
        .gs1-icon .gs1-icon__icon {
          background-color: var(--color-main-22);
        }
      }
      .description,
      .changes,
      .changes li {
        color: var(--color-main-4);
      }
    }
  }
}
</style>
