export default class RequestHeader {
  /**
   * Название заголовка
   */
  name: string;

  /**
   * Значение заголовка
   */
  value: string;

  constructor(name: string, value: string) {
    this.name = name;
    this.value = value;
  }
}