<template>
  <div id="api-page" :class="classes">
    <GS1Curtain
      v-model:active="requestListActive"
      class="api-page__left-container"
      :width="220"
      :min-width="220"
      :max-width="300"
      togglable
      resizable
      :toggle-tooltip="requestListToggleTooltip"
    >
      <ApiRequestList />
    </GS1Curtain>

    <div v-if="!loading['api-request']" class="api-page__right-container scrollable">
      <GS1AlertBlock v-if="apiRequest.api == 'srs' && currentAccess.length > 0 && !srsAccess.read && !srsAccess.write">
        Ваш текущий уровень доступа к api: {{ currentAccess }}. Чтобы изменить уровень доступа, заполните
        <router-link :to="{ name: 'gs1-api-access', params: { api: 'srs' } }">
          <u> форму </u>
        </router-link>
      </GS1AlertBlock>
      <div class="api-page__heading display-bold">
        <div class="api-page__heading-left">
          <h1 class="header-text display-large display-bold">API {{ apiRequest.name }}</h1>
          <span class="display-block secondary-text__margin font-gray">{{ apiRequest.description }}</span>
        </div>
        <div class="api-page__heading-right">
          <GS1Btn v-if="ableToTry" class="api__ty-button" no-margin big primary @click.prevent="showTestPanel">Попробовать</GS1Btn>
        </div>
      </div>

      <div class="api-page__content">
        <h3 class="api__secondary-text display-small">Запрос</h3>
        <div class="content__method">
          <span class="header-text font-gray request-method__text">
            {{ apiRequest.method + ' ' }}<span class="request-method__secondary-text font-gray">{{ apiRequest.request_url }}</span>
          </span>
        </div>

        <div v-if="typeof apiRequest.headers_formatted !== 'undefined' && apiRequest.headers_formatted.length > 0" class="content__text-secondary">
          <h3 class="api__secondary-text display-small">Заголовки запроса</h3>
          <h4 class="api__secondary-text display-secondary">Обязательные заголовки:</h4>
          <span class="content__text-secondary font-gray" v-html="apiRequest.headers_formatted"></span>
        </div>

        <div v-if="typeof apiRequest.body_formatted !== 'undefined' && apiRequest.body_formatted.length > 0" class="content__text-secondary">
          <h3 class="api__secondary-text display-small">Тело запроса</h3>
          <span class="content__text-secondary font-gray" v-html="apiRequest.body_formatted"></span>
        </div>

        <div
          v-if="typeof apiRequest.example !== 'undefined' && apiRequest.example.length > 0 && apiRequest.method == 'post'"
          class="content__text-secondary"
        >
          <h3 class="header-text display-small">Пример тела запроса</h3>
          <GS1CodeViewer class="content__text-secondary" :value="apiRequest.example"></GS1CodeViewer>
        </div>

        <div
          v-if="
            typeof apiRequest.request_example !== 'undefined' &&
            apiRequest.request_example &&
            apiRequest.request_example.length > 0 &&
            apiRequest.method == 'get'
          "
          class="content__text-secondary"
        >
          <h3 class="api__secondary-text display-small">Пример запроса</h3>
          <GS1CodeViewer class="content__text-secondary" :value="apiRequest.request_example"></GS1CodeViewer>
        </div>

        <div
          v-if="
            typeof apiRequest.parameters_formatted !== 'undefined' && apiRequest.parameters_formatted && apiRequest.parameters_formatted.length > 0
          "
          class="content__text-secondary"
        >
          <h3 class="api__secondary-text display-small">Параметры запроса</h3>
          <span class="content__text-secondary font-gray" v-html="apiRequest.parameters_formatted"></span>
        </div>

        <div v-for="apiResponse in apiRequest.responses" :key="apiResponse.id" class="content__text-secondary content__code-margin">
          <h3 class="api__secondary-text display-small">Ответ {{ apiResponse.code }}</h3>
          <span class="content__text-secondary font-gray" v-html="apiResponse.description"></span>
          <GS1CodeViewer :value="apiResponse.example"></GS1CodeViewer>
        </div>

        <div v-if="typeof apiRequest.errors !== 'undefined' && apiRequest.errors && apiRequest.errors.length > 0" class="content__text-secondary">
          <h3 class="api__secondary-text display-small">ErrorCode</h3>
          <span class="content__text-secondary font-gray">{{ apiRequest.errors }}</span>
        </div>

        <GS1AlertBlockInfo
          v-if="
            typeof apiRequest.restrictions_formatted !== 'undefined' &&
            apiRequest.restrictions_formatted &&
            apiRequest.restrictions_formatted.length > 0
          "
          class="content__text-secondary"
          accent
          icon="error"
        >
          <template #title>
            <span class="api-page__error-header">Ограничения!</span>
          </template>
          <span class="api-page__container__error-message" v-html="apiRequest.restrictions_formatted"></span>
        </GS1AlertBlockInfo>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, computed, onMounted, onUnmounted, ref, watch } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ApiRequestList from '../components/ApiRequestList.vue';
import router from '@/router';
import type EventBus from '@/models/EventBus';

export default defineComponent({
  name: 'ApiRequest',
  components: {
    ApiRequestList,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const bus: EventBus | undefined = inject('bus');
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    const requestTest: Ref<any> = ref(null);

    const exampleBlock: Ref<any> = ref(null);

    const currentAccess: Ref<string> = ref('');
    const requestListActive: Ref<boolean> = ref(true);
    const requestListToggleTooltip: ComputedRef<string> = computed(() => (requestListActive.value ? 'Скрыть' : 'Показать'));

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    const onRequestPage: ComputedRef<boolean> = computed(() => typeof route.params.method !== 'undefined');

    /**
     * Объект запроса
     */
    const apiRequest = computed(() => store.state.vbgApi.apiRequest);

    /**
     * Пример запроса
     */
    const requestExample = computed(() => apiRequest.value.example);

    /**
     * Флаги загрузки
     */
    const srsAccess: ComputedRef<Record<string, boolean>> = computed(() => store.state.vbgApi.srsAccess);

    /**
     * Возможность тестирования запроса
     */
    const ableToTry = computed(() => {
      return (
        onRequestPage.value && ((apiRequest.value.api == 'srs' && (srsAccess.value.read || srsAccess.value.write)) || apiRequest.value.api != 'srs')
      );
    });

    /**
     * Получение инорфмации о запросе
     */
    const getApiRequest = () => {
      // Проверка, при которой не будут вызываться повторные запросы на информаци о запросе api, если нужный запрос уже был загружен
      if (
        typeof apiRequest.value !== 'undefined' &&
        typeof route.params.method !== 'undefined' &&
        typeof route.params.request !== 'undefined' &&
        typeof route.params.version !== 'undefined' &&
        apiRequest.value.method == route.params.method &&
        apiRequest.value.request == route.params.request &&
        apiRequest.value.api_version == route.params.version
      ) {
        return;
      }

      return store.dispatch('vbgApi/getApiRequest', route.params).then(() => {
        if (typeof apiRequest.value.name === 'undefined') {
          router.push({ name: 'gs1-api' });
        }

        store.commit('setH1', 'API ' + apiRequest.value.name);
        if (apiRequest.value.api == 'srs') {
          store.dispatch('vbgApi/getSrsAccess').then(() => {
            if (srsAccess.value.write) currentAccess.value = 'доступ на чтение и запись';
            else if (srsAccess.value.read) currentAccess.value = 'доступ на чтение';
            else currentAccess.value = 'без доступа';
          });
        }
      });
    };

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'api-page__container': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    /**
     * Отображение/скрытие панели для тестов
     */
    const showTestPanel = () => {
      router.push({ name: 'gs1-api-request-test' });
    };

    const onCloseSidePanel = () => {
      router.push({ name: 'gs1-api-request' });
    };

    onMounted(() => {
      getApiRequest();

      // Слушатель события клика на кнопке закрытия правой панели
      if (bus) bus.on('close-sidepanel', onCloseSidePanel);
    });

    onUnmounted(() => {
      if (bus) bus.off('close-sidepanel', onCloseSidePanel);
    });

    watch(
      () => route.params.method,
      () => {
        getApiRequest();
      },
    );
    watch(
      () => route.params.request,
      () => {
        getApiRequest();
      },
    );
    watch(
      () => route.params.version,
      () => {
        getApiRequest();
      },
    );

    return {
      requestTest,
      exampleBlock,
      currentAccess,
      apiRequest,
      requestExample,
      srsAccess,
      loading,
      requestListActive,
      requestListToggleTooltip,
      onRequestPage,
      classes,
      getApiRequest,
      showTestPanel,
      ableToTry,
    };
  },
});
</script>

<style lang="scss">
.api-page__container {
  margin: calc(var(--margin-x4) * -1);
  display: flex;
  height: calc(100% + (var(--margin-x4) * 2));
  font-size: 'SF UI Display';
  .gs1-element.gs1-curtain {
    margin-left: var(--padding-x25);
  }
  .api-page__heading {
    border-color: var(--color-main-22);
  }
  .api-page__right-container {
    padding: var(--padding-x3) var(--padding-x4) var(--padding-x3) var(--padding-x25);
    flex: 1 1 auto;
    .api-page__content {
      .secondary-text__margin {
        margin-bottom: var(--margin-x4);
      }
    }
  }
  .header-text {
    margin-bottom: var(--margin-x3);
  }
  .api__secondary-text {
    font-weight: 600;
    margin-bottom: var(--margin-x3);
  }
  .api__ty-button {
    margin-bottom: var(--margin-x4);
  }
  .content__method {
    display: flex;
  }
  .content__text-secondary {
    margin-bottom: var(--margin-x3);
  }
  .api-page__container__error-message {
    margin-bottom: var(--margin-x3);
    color: var(--color-additional-17);
  }
  .content__code-margin {
    .gs1-element.gs1-code-viewer {
      margin-top: var(--margin-x3);
    }
  }
  .request-method__text {
    text-transform: uppercase;
    font-weight: 500;
    color: var(--color-main-8);
  }
  .request-method__secondary-text {
    text-transform: none;
    font-weight: 400;
  }
  .api-page__error-header {
    margin-bottom: var(--margin-x25);
  }

  .api-page__heading {
    font-family: 'SF UI Display';
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: var(--padding-x3);
    border-bottom: 1px solid var(--color-main-10);
    margin-bottom: var(--margin-x3);
    h1 {
      font-weight: 500;
      margin-bottom: var(--margin-x3);
      font-family: 'SF UI Display';
    }
    .gs1-element.gs1-btn {
      height: 32px;
      font-weight: 500;
      padding: 0 var(--padding-x2);
      font-size: 13px;
    }
  }

  h3 {
    font-weight: 600;
  }

  &.mode-dark {
    .api-page__heading-left {
      color: var(--color-main-f);
      .secondary-text__margin {
        color: var(--color-main-4);
      }
    }
    .api-page__container__error-message {
      color: var(--color-main-f);
    }
    .api-page__right-container {
      .api__secondary-text {
        color: var(--color-main-f);
      }
    }
    .content__text-secondary {
      .header-text {
        color: var(--color-main-f);
      }
    }
    .content__method {
      .request-method__secondary-text {
        color: var(--color-main-4);
      }
    }
    .content__text-secondary {
      color: var(--color-main-4);
    }
    .api-page__heading {
      border-bottom: 1px solid var(--color-main-22);
    }
  }
}
</style>
