<template>
  <div class="search-filter list-filter">
    <div class="first-row">
      <template v-if="showDateRange">
        <SelectInput
          v-if="dateRangeType == 'select'"
          v-model="dateRange"
          class="date-range-select"
          :options="dateRangeOptions"
          @select="setDateRange"
        />

        <div v-if="dateRangeType == 'radio'" class="date-range-radio">
          <div
            v-for="dateRangeOption in dateRangeOptions"
            :key="dateRangeOption.value"
            :class="{ active: dateRange == dateRangeOption.value }"
            class="date-range-option"
          >
            <input
              :id="'date-range-radio-' + dateRangeOption.value"
              v-model="dateRange"
              type="radio"
              :value="dateRangeOption.value"
              @change="setDateRange"
            />
            <label :for="'date-range-radio-' + dateRangeOption.value">
              {{ dateRangeOption.label }}
            </label>
          </div>
        </div>
      </template>

      <DateCalendar v-if="showCalendar" v-model="dateValue" is-range />

      <slot name="after" />

      <a href="#" class="reset-filter" @click.prevent="resetFilter">
        <i class="fas fa-times" />
      </a>
    </div>

    <div class="second-row">
      <div v-if="showSearch" class="search-input">
        <i class="icon-search-2" />

        <input v-model="searchStringValue" type="text" placeholder="Поисковый запрос" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import type { Ref } from 'vue';
import DateCalendar from '@/components/DateCalendar.vue';
import dayjs from 'dayjs';
import SelectInput from '@/components/SelectInput.vue';

export default defineComponent({
  name: 'ListFilter',
  components: {
    DateCalendar,
    SelectInput,
  },
  props: {
    date: {
      type: Object,
      default: (): Record<string, Date> => ({}),
    },
    showDateRange: {
      type: Boolean,
      default: false,
    },
    showCalendar: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    dateRangeType: {
      type: String,
      default: 'select',
    },
    searchString: {
      type: String,
      default: '',
    },
  },
  emits: ['search', 'reset', 'update:date', 'update:searchString'],
  setup(props, { emit }) {
    const dateRange: Ref<string> = ref('week');
    const route = useRoute();
    const dateValue = computed<Record<string, Date>>({
      get: () => props.date,
      set: (value: any) => emit('update:date', value),
    });
    const dateRangeOptions = ref([
      { value: 'today', label: 'Сегодня' },
      { value: 'yesterday', label: 'Вчера' },
      { value: 'week', label: 'Неделя' },
      { value: 'month', label: 'Месяц' },
      { value: 'quarter', label: 'Квартал' },
      { value: 'year', label: 'Год' },
    ]);

    const searchStringValue = computed({
      get: () => props.searchString,
      set: (value: any) => {
        emit('update:searchString', value);
        emit('search', value);
      },
    });
    const searchMounted = () => {
      if (typeof route.query.search === 'string') searchStringValue.value = route.query.search;
    };

    /**
     * Сброс фильтра
     */
    const resetFilter = (): void => {
      dateRange.value = 'week';
      emit('reset');
    };

    /**
     * Установка промежутка дат фильтра
     */
    const setDateRange = (): void => {
      switch (dateRange.value) {
        case 'today':
          dateValue.value.start = dayjs().hour(0).minute(0).toDate();
          dateValue.value.end = dayjs().hour(23).minute(59).toDate();
          break;
        case 'yesterday':
          dateValue.value.start = dayjs().subtract(1, 'day').hour(0).minute(0).toDate();
          dateValue.value.end = dayjs().subtract(1, 'day').hour(23).minute(59).toDate();
          break;
        case 'week':
          dateValue.value.start = dayjs().subtract(1, 'week').hour(0).minute(0).toDate();
          dateValue.value.end = dayjs().hour(23).minute(59).toDate();
          break;
        case 'month':
          dateValue.value.start = dayjs().subtract(1, 'month').hour(0).minute(0).toDate();
          dateValue.value.end = dayjs().hour(23).minute(59).toDate();
          break;
        case 'quarter':
          dateValue.value.start = dayjs().subtract(3, 'month').hour(0).minute(0).toDate();
          dateValue.value.end = dayjs().hour(23).minute(59).toDate();
          break;
        case 'year':
          dateValue.value.start = dayjs().subtract(1, 'year').hour(0).minute(0).toDate();
          dateValue.value.end = dayjs().hour(23).minute(59).toDate();
          break;
      }
      watch(
        () => route.query.search,
        (search) => {
          // @ts-expect-error searchStringValue не может быть null в данном контексте
          searchStringValue.value = search;
        }
      );
      watch(
        () => dateValue,
        () => emit('search', searchStringValue.value)
      );
    };

    searchMounted();

    return {
      dateRange,
      resetFilter,
      setDateRange,
      dateRangeOptions,
      dateValue,
      searchStringValue,
    };
  },
});
</script>

<style lang="scss">
.list-filter {
  margin-bottom: 48px;
  .first-row {
    display: flex;
    align-items: center;
    > select,
    > input,
    > div {
      + select,
      + input,
      + div {
        margin-left: 24px;
      }
    }
  }
  input[type='text'],
  .date-range-option label {
    appearance: none;
    height: 30px;
    padding: 0 10px;
    border: 1px solid #b1b3b3;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    align-items: center;
    outline: none;
    color: #f26334;
    font-weight: bold;
    font-size: 14px;
  }
  .date-range-radio {
    display: flex;
    .date-range-option {
      &:first-child {
        label {
          border-radius: 3px 0 0 3px;
        }
      }
      &:last-child {
        label {
          border-radius: 0 3px 3px 0;
          border-right: 1px solid #b1b3b3;
        }
      }
      &.active {
        label {
          color: #fff;
          background: #f26334;
        }
      }
      input {
        display: none;
      }
      label {
        cursor: pointer;
        margin: 0;
        width: 90px;
        justify-content: center;
        color: #888b8d;
        border-radius: 0;
        border-right: 0;
      }
    }
  }
  .reset-filter {
    height: 24px;
    width: 24px;
    border: 2px solid;
    color: #888b8d;
    border-radius: 50%;
    margin-left: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
    i {
      height: 10px;
    }
    &:hover {
      color: #f26334;
    }
  }
  .search-input {
    position: relative;
    margin-top: 25px;
    input {
      width: 650px;
      transition: border-color 0.3s;
      height: 40px;
      padding-left: 40px;
      font-weight: normal;
      color: #888b8d;
      &:focus {
        border-color: #002c6c;
      }
    }
    i {
      position: absolute;
      color: #b1b3b3;
      font-size: 16px;
      height: 16px;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 12px;
    }
  }
}
</style>
