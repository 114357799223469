import { Store } from 'vuex';
import store from './store';

export default class GtinSearchModule {
  router: Record<string, any>;
  store: Store<any>;

  constructor(router: Record<string, any>, store: Store<any>) {
    this.router = router;
    this.store = store;
  }

  init() {
    this.addRoutes();
    this.store.registerModule('user', store);
  }

  addRoutes(): void {}
}
