<template>
  <div class="api-test__container">
    <h3 class="margin-header headers-header">
      Заголовки
      <GS1BtnIconEdit class="headers gs-margin-left" no-margin :icon="['plus']" @click="addHeader">Добавить заголовок</GS1BtnIconEdit>
    </h3>
    <div v-for="(header, index) in headers" :key="index" class="api-test__table-container">
      <GS1Input v-model="header.name" class="api-test__header-name" small></GS1Input>
      <GS1Input v-model="header.value" small></GS1Input>
      <GS1BtnIcon class="api-test__close-button" :icon="['trash']" no-margin transparent @click.prevent="removeHeader(index)"></GS1BtnIcon>
    </div>

    <template v-if="api.soap">
      <h3 class="margin-header header__margin-top">Авторизация в SOAP</h3>
      <div class="api-test__table-container">
        <GS1Input v-model="soapLogin" class="api-test__header-name" placeholder="Логин SOAP" small></GS1Input>
        <GS1Input v-model="soapPassword" placeholder="Пароль SOAP" small></GS1Input>
      </div>
    </template>

    <h3 class="margin-header header__margin-top">Тело запроса</h3>
    <div class="api-test__request">
      <GS1Textarea
        v-model="bodyValue"
        :disabled="apiRequest.method == 'get'"
        class="api-test__request-body gs-margin-left"
        :placeholder="apiRequest.method == 'get' ? 'Тело запроса не указывается для GET запроса' : ''"
      ></GS1Textarea>
      <div class="api-test__request__buttons">
        <GS1BtnIcon v-gs1-tooltip.left="'Очистить тело запроса'" :icon="['trash']" no-margin transparent @click.prevent="clearBodyValue"></GS1BtnIcon>
        <GS1BtnIcon
          v-show="headers.length > 0"
          v-gs1-tooltip.left="'Отправить и получить ответ'"
          :disabled="sentRequest"
          :icon="['send']"
          no-margin
          transparent
          @click.prevent="send"
        ></GS1BtnIcon>
      </div>
    </div>

    <h3 v-if="response.length > 0 || sentRequest" class="margin-header header__margin-top">Ответ на запрос</h3>
    <GS1Loader v-if="sentRequest" text-value="" note-value="Обработка запроса..."></GS1Loader>
    <GS1CodeViewer v-if="response.length > 0 && !sentRequest" :value="response"></GS1CodeViewer>

    <h3 class="margin-header header__margin-top">Пример кода</h3>
    <GS1Tabs>
      <GS1TabsItem :active="exampleTab == 'php'" @click.prevent="setExampleTab('php')">PHP</GS1TabsItem>
      <GS1TabsItem v-if="!api.soap" :active="exampleTab == 'javascript'" @click.prevent="setExampleTab('javascript')">JavaScript</GS1TabsItem>
      <GS1TabsItem :active="exampleTab == 'python'" @click.prevent="setExampleTab('python')">Python</GS1TabsItem>
      <GS1TabsItem :active="exampleTab == 'http'" @click.prevent="setExampleTab('http')">HTTP</GS1TabsItem>
    </GS1Tabs>

    <ExampleJavaScript v-if="exampleTab == 'javascript'" :api-request="apiRequest" :headers="headers" :body="bodyValue" />
    <ExamplePHP v-if="exampleTab == 'php' && !api.soap" :api-request="apiRequest" :headers="headers" :body="bodyValue" />
    <ExamplePython v-if="exampleTab == 'python' && !api.soap" :api-request="apiRequest" :headers="headers" :body="bodyValue" />
    <ExampleHTTP v-if="exampleTab == 'http' && !api.soap" :api-request="apiRequest" :headers="headers" :body="bodyValue" />
    <ExamplePHPSoap
      v-if="exampleTab == 'php' && api.soap"
      :api-request="apiRequest"
      :headers="headers"
      :body="bodyValue"
      :soap-login="soapLogin"
      :soap-password="soapPassword"
    />
    <ExamplePythonSoap
      v-if="exampleTab == 'python' && api.soap"
      :api-request="apiRequest"
      :headers="headers"
      :body="bodyValue"
      :soap-login="soapLogin"
      :soap-password="soapPassword"
    />
    <ExampleHTTPSoap
      v-if="exampleTab == 'http' && api.soap"
      :api-request="apiRequest"
      :headers="headers"
      :body="bodyValue"
      :soap-login="soapLogin"
      :soap-password="soapPassword"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from 'vue';
import type { ComputedRef, Ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import RequestHeader from '../models/RequestHeader';
import axios from 'axios';
import User from '@/modules/auth/models/User';
import ExampleJavaScript from './ExampleJavaScript.vue';
import ExamplePHP from './ExamplePHP.vue';
import ExamplePython from './ExamplePython.vue';
import ExampleHTTP from './ExampleHTTP.vue';
import ExamplePHPSoap from './ExamplePHPSoap.vue';
import ExamplePythonSoap from './ExamplePythonSoap.vue';
import ExampleHTTPSoap from './ExampleHTTPSoap.vue';

export default defineComponent({
  name: 'ApiRequestTest',
  components: {
    ExampleJavaScript,
    ExamplePHP,
    ExamplePython,
    ExampleHTTP,
    ExamplePHPSoap,
    ExamplePythonSoap,
    ExampleHTTPSoap,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    /**
     * Заголовки запроса
     */
    const headers: Ref<RequestHeader[]> = ref([]);

    /**
     * Флаг активности запроса
     */
    const sentRequest: Ref<boolean> = ref(false);

    /**
     * Логин SOAP
     */
    const soapLogin: Ref<string> = ref('');

    /**
     * Пароль SOAP
     */
    const soapPassword: Ref<string> = ref('');

    /**
     * Ответ
     */
    const response: Ref<string> = ref('');

    /**
     * Выбранная акладка с примером кода
     */
    const exampleTab: Ref<string> = ref('php');

    /**
     * Флаги загрузки
     */
    const loading: ComputedRef<any> = computed(() => store.state.loading);

    const userAgent: ComputedRef<string> = computed(() => navigator.userAgent);

    /**
     * Объект с данными авторизованного пользователя
     */
    const user: ComputedRef<User> = computed(() => store.state.auth.user);

    /**
     * Объект API
     */
    const api = computed(() => store.state.vbgApi.api);

    /**
     * Тело запроса
     */
    const bodyValue: Ref<string> = ref('');

    /**
     * Объект запроса
     */
    const apiRequest = computed(() => store.state.vbgApi.apiRequest);

    /**
     * Флаг наличие тела запроса
     */
    const hasBodyValue: ComputedRef<boolean> = computed(() => typeof bodyValue.value !== 'undefined' && bodyValue.value.length > 0);

    /**
     * Токен для текущего API
     */
    const userToken: ComputedRef<string> = computed(() => {
      return typeof route.params.api == 'string' && typeof user.value.tokens[route.params.api] !== 'undefined'
        ? user.value.tokens[route.params.api].token
        : '';
    });

    /**
     * Темная/светлая тема приложения
     */
    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);

    /**
     * Добавление заголовка
     */
    const addHeader = () => {
      headers.value.push(new RequestHeader('', ''));
    };

    /**
     * Удаление заголовка
     */
    const removeHeader = (index: number) => {
      headers.value.splice(index, 1);
    };

    /**
     * Активация влкадки с примером кода запроса
     */
    const setExampleTab = (tab: string) => {
      exampleTab.value = tab;
    };

    /**
     * Очиста поля с телом запроса
     */
    const clearBodyValue = () => {
      bodyValue.value = '';
    };

    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        'api-test__container': true,
        ['mode-' + ViewMode.value]: true,
      };
    });

    /**
     * Отправление запроса
     */
    const send = async () => {
      if (sentRequest.value) return;

      const method = apiRequest.value.method as 'post' | 'get' | 'delete' | 'put';
      const newHeaders: Record<string, any> = {};

      headers.value.forEach((header: RequestHeader) => {
        if (header.name.length > 0) newHeaders[header.name] = header.value;
      });
      if (api.value.soap) {
        newHeaders['SOAPLogin'] = soapLogin.value;
        newHeaders['SOAPPassword'] = soapPassword.value;
      }

      sentRequest.value = true;

      axios({
        method: method,
        url: apiRequest.value.request_example,
        data: bodyValue.value,
        headers: newHeaders,
      })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((r: Record<string, any>) => {
          response.value = api.value.soap ? r.data : JSON.stringify(r.data, null, 4);
        })
        .catch((error: Record<string, any>) => {
          response.value = JSON.stringify(error.response.data, null, 4);
        })
        .finally(() => {
          sentRequest.value = false;
        });
    };

    onMounted(() => {
      bodyValue.value = apiRequest.value.example;

      store.dispatch('vbgApi/getApi', route.params.api).then(() => {
        if (api.value.soap) {
          soapLogin.value = user.value.login;

          headers.value.push(new RequestHeader('Content-Type', 'text/xml; charset=UTF-8'));
          headers.value.push(new RequestHeader('User-Agent', userAgent.value));
        } else {
          headers.value.push(new RequestHeader('Content-Type', 'application/json'));
          headers.value.push(new RequestHeader('Token', userToken.value));

          if (route.params.api == 'srs') {
            headers.value.push(new RequestHeader('SrsLogin', user.value.login));
            headers.value.push(new RequestHeader('SrsPassword', ''));
          }
        }
      });
    });

    watch(
      () => apiRequest.value.example,
      () => {
        bodyValue.value = apiRequest.value.example;
      }
    );

    watch(
      () => bodyValue.value,
      () => {
        if (apiRequest.value.format == 'xml') {
          store.dispatch('vbgApi/updateSoapExample', {
            body: bodyValue.value,
            id: apiRequest.value.id,
          });
        }
      }
    );

    return {
      bodyValue,
      soapLogin,
      soapPassword,
      response,
      api,
      classes,
      exampleTab,
      loading,
      headers,
      userToken,
      userAgent,
      apiRequest,
      sentRequest,
      hasBodyValue,
      addHeader,
      removeHeader,
      setExampleTab,
      send,
      clearBodyValue,
    };
  },
});
</script>

<style lang="scss">
.api-test__container {
  h3 {
    font-size: 13px;
  }
  .headers-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .gs1-element.gs1-textarea {
    margin-left: 0;
  }
  .gs1-element.gs1-btn {
    margin-left: 0;
  }
  .margin-header {
    margin-bottom: var(--margin-x3);
  }

  .gs1-element.gs1-textarea .gs1-textarea__input {
    height: 120px;
    font-size: 12px;
  }
  .api-test__request {
    display: flex;
    align-items: flex-start;
    .api-test__request-body {
      margin: 0;
      flex: 0 0 calc(100% - var(--margin-x25) - 32px);
    }
    .api-test__request__buttons {
      margin-left: var(--margin-x25);
      .gs1-btn-icon + .gs1-btn-icon {
        margin-top: var(--margin-x2);
      }
    }
  }
  .gs1-element.gs1-tabs {
    margin: var(--margin-x3) 0;
    .gs1-element.gs1-tabs-item {
      flex: 1 1 auto;
    }
  }
  .header__margin-top {
    margin-top: var(--margin-x3);
  }
  .api-test__table-container {
    display: flex;
    .gs1-element.gs1-input {
      margin: 0 var(--margin-x2) var(--margin-x2) var(--margin-x2);
    }
    .gs1-element.gs1-input.api-test__header-name {
      width: 260px;
      margin-left: 0;
    }
    .api-test__close-button {
      border-radius: 8px;
    }
  }
  .gs-margin-left {
    margin-left: 0;
  }

  &.mode-dark {
    .gs1-element.gs1-input {
      .gs1-input__input {
        color: var(--color-main-f);
      }
    }
    .gs1-element.gs1-textarea {
      .gs1-textarea__input {
        color: var(--color-main-f);
      }
    }
  }
}
</style>
