import type EventBus from '@/models/EventBus';

export default (): EventBus => {
  const events: Record<string, Array<CallableFunction>> = {};

  const on = (event: string, callback: CallableFunction): void => {
    if (typeof events[event] === 'undefined' || !events[event]) events[event] = [];
    events[event].push(callback);
  };

  const off = (event: string, callback: CallableFunction): void => {
    if (typeof events[event] === 'undefined' || !events[event]) throw new Error(`Event ${event} does't have any listeners`);

    const i = events[event].findIndex((item: CallableFunction) => item === callback);
    if (i === -1) return;
    events[event].splice(i, 1);
  };

  const emit = (event: string, ...args: Array<any>): void => {
    if (typeof events[event] === 'undefined' || !events[event]) return;
    events[event].map((callback: CallableFunction) => callback(...args));
  };

  return {
    events,
    on,
    off,
    emit,
  };
};
