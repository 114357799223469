import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select-input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Multiselect, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      "multiple-label": _ctx.multipleLabel,
      "hide-selected": false,
      "close-on-select": _ctx.closeOnSelect,
      placeholder: _ctx.placeholder,
      mode: _ctx.mode,
      "can-deselect": false,
      options: _ctx.options,
      onSelect: _ctx.select,
      onDeselect: _ctx.deselect
    }, null, 8, ["modelValue", "multiple-label", "close-on-select", "placeholder", "mode", "options", "onSelect", "onDeselect"])
  ]))
}