import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GS1BtnIcon = _resolveComponent("GS1BtnIcon")!

  return (_openBlock(), _createBlock(_component_GS1BtnIcon, {
    icon: ['copy'],
    "no-margin": "",
    transparent: "",
    small: "",
    onClick: _withModifiers(_ctx.copy, ["stop"])
  }, null, 8, ["onClick"]))
}