<template>
  <div ref="outer" :style="{ 'max-height': height + 'px' }" class="short-text" :class="{ expanded: expanded }" @click="toggle">
    <div ref="inner" class="short-text-inner">
      <slot />
    </div>

    <i v-if="showToggle" class="fas fa-chevron-down toggle" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick } from 'vue';
import type { Ref } from 'vue';

export default defineComponent({
  name: 'ShortText',
  props: {
    height: {
      type: String,
      default: '',
    },
  },
  emits: ['expanded'],
  setup(props, { emit }) {
    const outer: Ref<InstanceType<any> | null> = ref(null);
    const inner: Ref<InstanceType<any> | null> = ref(null);
    /**
     * Блок развёрнут
     */
    const expanded: Ref<boolean> = ref(false);
    const showToggle: Ref<boolean> = ref(false);
    const onNextTick = () => {
      nextTick(() => {
        showToggle.value = inner.value && inner.value.offsetHeight > outer.value.offsetHeight;
      });
    };
    const toggle = (): void => {
      expanded.value = !expanded.value;

      if (expanded.value) {
        emit('expanded');
      }
    };

    onNextTick();
    return {
      expanded,
      showToggle,
      toggle,
    };
  },
});
</script>

<style lang="scss">
.short-text {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  &.expanded {
    max-height: 3000px !important;
    transition: max-height 1s ease-in-out;
    .toggle {
      transform: rotate(180deg);
    }
  }
  .toggle {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 10px;
    color: #002c6c;
    transition: transform 0.3s;
  }
}
</style>
