<template>
  <div id="api-example-javascript">
    <GS1CodeViewer :value="example"></GS1CodeViewer>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef, PropType } from 'vue';
import RequestHeader from '../models/RequestHeader';

export default defineComponent({
  name: 'ExampleJavaScript',
  props: {
    apiRequest: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array as PropType<RequestHeader[]>,
      default: () => [],
    },
    body: {
      type: String,
      default: '',
    },
    soapLogin: {
      type: String,
      default: '',
    },
    soapPassword: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /**
     * Доступы в base64
     */
    const base64Credentials: ComputedRef<string> = computed(() => btoa(props.soapLogin + ':' + props.soapPassword));

    const example: ComputedRef<string> = computed(() => {
      let example = '';

      if (typeof props.apiRequest.method !== 'undefined') {
        example += props.apiRequest.method.toUpperCase() + ' ' + props.apiRequest.request_url + ' HTTP/1.1\n\n';

        props.headers.forEach((header: RequestHeader) => {
          if (header.value && header.name) example += header.name + ': ' + header.value + '\n';
        });
        example += 'Content-Length: ' + new TextEncoder().encode(props.body).length + '\n';
        example += 'Authorization: Basic ' + base64Credentials.value + '\n';

        example += '\n' + props.body;
      }

      return example;
    });

    return {
      base64Credentials,
      example,
    };
  },
});
</script>

<style lang="scss"></style>
