<template>
  <h2 class="display-large">
    {{ apiRequest.name }}
  </h2>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ApiRequestTestHeader',
  setup() {
    const store = useStore();

    /**
     * Объект запроса
     */
    const apiRequest = computed(() => store.state.vbgApi.apiRequest);

    return {
      apiRequest,
    };
  },
});
</script>

<style lang="scss" scoped>
h2.display-large {
  margin: 0;
  font-size: 14px;
}
</style>
