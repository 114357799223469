export default {
  mounted: (el: HTMLElement, binding: any) => {
    el.addEventListener('mouseenter', () => {
      const viewportOffset = el.getBoundingClientRect();
      const popup = document.createElement('div');
      popup.classList.add('expanded-block');
      popup.style.position = 'fixed';
      popup.style.left = (viewportOffset.left - 1) + 'px';
      popup.style.top = viewportOffset.top + 'px';
      popup.style.width = el.offsetWidth + 'px';
      popup.style.minWidth = '300px';
      popup.style.padding = getComputedStyle(el).padding;
      popup.style.border = '1px solid #B1B3B3';
      popup.style.background = '#ffffff';
      popup.style.boxSizing = 'border-box';
      popup.style.overflowWrap = 'anywhere';
      popup.innerText = el.innerText;

      document.querySelectorAll('.expanded-block').forEach((el: Element) => {
        el.remove();
      });
      
      if (typeof binding.value == 'function') popup.addEventListener('click', binding.value);
      popup.addEventListener('mouseleave', (e: any) => {
        e.target.remove();
      });
  
      document.querySelector('#app')?.appendChild(popup);
    });
  }
};