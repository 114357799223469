export default {
  updated: (el: HTMLElement) => {
    const nodeName = el.nodeName;
    if (['TABLE'].indexOf(nodeName) < 0) return;

    const table = el;
    table.classList.add('resizable-columns');
    const thead = table.querySelector('thead');

    if (!thead) return;

    const ths = thead.querySelectorAll('th');
    const barHeight = table.offsetHeight;

    const resizeContainer = document.createElement('div');
    table.style.position = 'relative';
    resizeContainer.style.position = 'relative';
    resizeContainer.style.width = table.offsetWidth + 'px';
    resizeContainer.className = "vue-columns-resizable";

    if (!table.parentElement) return;

    table.parentElement.insertBefore(resizeContainer, table);

    let moving = false;
    let movingIndex = 0;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (context) {
      context.font = getComputedStyle(document.body).font;
    }

    ths.forEach((th, index) => {
      th.style.width = th.offsetWidth + 'px';
      if (context) {
        th.dataset.minWidth = String(Math.round(context.measureText(th.innerText).width) + 32);
      }

      if (index + 1 >= ths.length) return;

      const bar = document.createElement('div');

      bar.style.position = 'absolute';
      bar.style.left = th.offsetLeft + th.offsetWidth - 4 + 'px';
      bar.style.top = '0';
      bar.style.height = barHeight + 'px';
      bar.style.width = '8px';
      bar.style.cursor = 'col-resize';
      bar.style.zIndex = '1';
      bar.className = 'columns-resize-bar';

      bar.addEventListener('mousedown', () => {
        moving = true;
        movingIndex = index;
        document.body.style.cursor = 'col-resize';
        document.body.style.userSelect = 'none';
      });

      resizeContainer.appendChild(bar);
    });

    const bars = resizeContainer.querySelectorAll('.columns-resize-bar');

    document.addEventListener('mouseup', () => {
      if (!moving) return;

      moving = false;
      document.body.style.cursor = '';
      document.body.style.userSelect = '';


      bars.forEach((bar: any, index: number) => {
        const th = ths[index];
        let colWidth = th.offsetWidth;

        if (colWidth <= Number(th.dataset.minWidth)) {
          colWidth = Number(th.dataset.minWidth);
        }

        th.style.width = colWidth + 'px';
        bar.style.left = th.offsetLeft + colWidth - 4 + 'px';
      });
    });

    const cutPx = (str: string) => +str.replace('px', '');

    const handleResize = (e: any) => {
      if (moving) {
        const th = ths[movingIndex];
        const nextTh = ths[movingIndex + 1];
        const bar = bars[movingIndex] as HTMLElement;

        const colWidth = cutPx(th.style.width);
        const nextColWidth = cutPx(nextTh.style.width);


        if (
          (
            colWidth > Number(th.dataset.minWidth) || 
            e.movementX > 0
          ) &&
          nextColWidth > Number(nextTh.dataset.minWidth)
        ) {
          th.style.width = colWidth + e.movementX + 'px';
        }
        if (
          (
            nextColWidth > Number(nextTh.dataset.minWidth) || 
            e.movementX < 0
          ) &&
          colWidth > Number(th.dataset.minWidth)
        ) {
          nextTh.style.width = nextColWidth - e.movementX + 'px';
        }
        bar.style.left = nextTh.offsetLeft - 4 + e.movementX + 'px';
      }
    };

    resizeContainer.addEventListener('mousemove', handleResize);
    table.addEventListener('mousemove', handleResize);
  }
};