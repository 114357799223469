<template>
  <div id="api-example-javascript">
    <GS1CodeViewer :value="example"></GS1CodeViewer>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { ComputedRef, PropType } from 'vue';
import RequestHeader from '../models/RequestHeader';

export default defineComponent({
  name: 'ExampleJavaScript',
  props: {
    apiRequest: {
      type: Object,
      default: () => ({}),
    },
    headers: {
      type: Array as PropType<RequestHeader[]>,
      default: () => [],
    },
    body: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const example: ComputedRef<any> = computed(() => {
      let example = '';

      if (typeof props.apiRequest.method !== 'undefined') {
        example += props.apiRequest.method.toUpperCase() + ' ' + props.apiRequest.request_example + ' HTTP/1.1\n\n';

        props.headers.forEach((header) => {
          if (header.value && header.name) example += header.name + ': ' + header.value + '\n';
        });

        example += '\n' + props.body;
      }

      return example;
    });

    return {
      example,
    };
  },
});
</script>

<style lang="scss"></style>
