export default class MenuLink {
  /**
   * Подпись ссылки
   */
  label: string;

  /**
   * Объект роута для vue-router
   */
  route: Record<string, string>;

  /**
   * Класс иконки icomoon
   */
  icon: Record<string, string>;

  /**
   * Индекс сортировки (от меньшего к большему)
   */
  sort: number;

  /**
   * Callback функция, которая будет выполнятся при клике
   */
  callback: Function;

  /**
   * Объект модуля, из которого вызывается добавление ссылки
   */
  module: any;

  /**
   * Массив дочерних ссылок (для бокового меню)
   */
  children: Array<MenuLink>;

  /**
   * Массив вложенных ссылок (для главного меню)
   */
  nested: Array<MenuLink>;

  /**
   * Компонент, которые будет вставлен перед пунктом меню
   */
  prefix: any;

  /**
   * Скрытое/раскрытое меню с дочерними пунктами
   */
  expanded: boolean;

  /**
   * Условие показа ссылки
   */
  condition: string;

  /**
   * Классы ссылки
   */
  class: Record<string, boolean>;

  active?: boolean;

  constructor(
    label: string,
    icon: Record<string, string>,
    sort: number,
    route: Record<string, string>,
    callback?: any,
    module?: any,
    prefix?: any,
    condition?: string,
    active?: boolean,
    nested?: Array<MenuLink>
  ) {
    this.label = label;
    this.route = route;
    this.icon = icon;
    this.sort = sort;
    this.children = [];
    this.nested = nested ? nested : [];
    this.expanded = false;
    this.prefix = prefix ? prefix : null;
    this.callback = callback ? callback : null;
    this.module = module ? module : {};
    this.condition = typeof condition !== 'undefined' ? condition : 'default';
    this.class = {};
    this.active = active;
  }

  hasChildren(): boolean {
    return this.children.length > 0;
  }
}
