import axios from 'axios';

export default {
  namespaced: true,
  state() {
    return {
      /**
       * Доступ к мобильному приложению
       */
      mobileAccess: false
    };
  },
  mutations: {
    /**
     * Установка доступа к мобильному приложению
     * 
     * @param state 
     * @param access Доступ к мобильному приложению
     */
    setMobileAccess(state: Record<string, any>, access: boolean) {
      state.mobileAccess = access;
    }
  },
  actions: {
    /**
     * Получение доступа к мобильному приложению
     * 
     * @param context 
     * @param data
     */
    async checkMobile({ commit }: { commit: any }): Promise<boolean> {
      commit('setLoading', { flagName: 'user', flagState: true }, { root: true });

      try {
        const response: Record<string, any> = await axios.get('users/check-mobile');
        commit('setMobileAccess', response.data.result);

        return true;
      } catch (errors) {
        commit('setMobileAccess', false);

        return false;
      } finally {
        commit('setLoading', { flagName: 'user', flagState: false }, { root: true });
      }
    },

    /**
     * Сохранение пароля от мобильного приложения
     * 
     * @param context 
     * @param data Параметры запроса (password - новый пароль)
     */
    async saveMobilePassword({ commit }: { commit: any }, data: Record<string, any>): Promise<boolean> {
      commit('setLoading', { flagName: 'user', flagState: true }, { root: true });

      try {
        await axios.post('users/save-mobile', { password: data.password });

        return true;
      } catch (errors) {
        return false;
      } finally {
        commit('setLoading', { flagName: 'user', flagState: false }, { root: true });
      }
    },

    /**
     * Удаление пароля от мобильного приложения
     * 
     * @param context 
     */
    async removeMobilePassword({ commit }: { commit: any }): Promise<boolean> {
      commit('setLoading', { flagName: 'user', flagState: true }, { root: true });

      try {
        await axios.post('users/remove-mobile');

        return true;
      } catch (errors) {
        commit('setMobileAccess', false);

        return false;
      } finally {
        commit('setLoading', { flagName: 'user', flagState: false }, { root: true });
      }
    }
  }
};
