import config from '@/config';
import ZPusher from 'zpusher-client';

let pusher = {
  subscribe(module) {
    return { module };
  },
  onEvent(event, callback) {
    return { event, callback };
  },
  on(event, callback) {
    return { event, callback };
  },
};

if (process.env.NODE_ENV !== 'test') {
  pusher = new ZPusher({
    token: config.pusherToken || '',
    endpoint: config.pusherUrl || '',
  });
}

export default pusher;
