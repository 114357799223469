<template>
  <div id="page-not-found" :class="classes">
    <h1 class="font-wbold">404</h1>
    <div class="font-wbold heading">Страница не найдена...</div>
    <div class="display-large content">
      Данной страницы не существует, возможно вы ошиблись с адресом,<br />
      попробуйте обновить страницу или перейти в другой раздел.
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from 'vue';
import type { ComputedRef } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'PageNotFound',
  components: {},
  setup() {
    const store = useStore();

    const ViewMode: ComputedRef<string> = computed(() => store.state.ViewMode);
    const classes: ComputedRef<{ [key: string]: boolean }> = computed((): { [key: string]: boolean } => {
      return {
        ['mode-' + ViewMode.value]: true,
      };
    });

    onMounted(() => {
      store.commit('setH1', '');
    });

    return {
      classes,
    };
  },
});
</script>

<style lang="scss">
#page-not-found {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 100px;
    height: 100px;
    color: var(--color-main-8);
    margin-bottom: var(--margin-x3);
  }

  .heading {
    font-size: 24px;
    margin-bottom: var(--margin-x3);
  }

  .content {
    color: var(--color-additional-24);
  }

  &.mode-dark {
    .heading {
      color: var(--color-main-f);
    }

    .content {
      color: var(--color-main-4);
    }
  }
}
</style>
