<template>
  <nav v-if="totalPages > 1" class="pages">
    <div class="total">
      <span>
        {{ totalResults }}
      </span>
      {{ totalResultLabel }}
    </div>

    <ul>
      <li :class="{ disabled: page == 1 }">
        <a href="#" @click.prevent="setPage(page - 1)">
          <i class="fas fa-angle-left" />
        </a>
      </li>
      <li v-for="n in pageLinks" :key="n" :class="{ active: n == page }">
        <a href="#" @click.prevent="setPage(n)">
          {{ n }}
        </a>
      </li>
      <li :class="{ disabled: page == totalPages }">
        <a href="#" @click.prevent="setPage(page + 1)">
          <i class="fas fa-angle-right" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import type { Ref, ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'Pagination',
  props: {
    totalPages: {
      type: Number,
      default: 1,
    },
    totalResults: {
      type: Number,
      default: 1,
    },
    setQuery: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    /**
     * Текущая страница
     */
    const page: Ref<number> = ref(1);
    /**
     * Подпись для кол-ва результатов
     */
    const totalResultLabel: ComputedRef<string> = computed(() => {
      const lastDigit = props.totalResults % 10;

      switch (lastDigit) {
        case 1:
          return 'результат';
        case 2:
        case 3:
        case 4:
          return 'результата';
        default:
          return 'результатов';
      }
    });

    /**
     * Номера страниц
     */
    const pageLinks: ComputedRef<Array<number>> = computed(() => {
      const links = [];

      for (let pageLink = 1; pageLink <= props.totalPages; pageLink++) {
        if (pageLink == 1 || pageLink == props.totalPages || Math.abs(page.value - pageLink) < 4) {
          links.push(pageLink);
        }
      }

      return links;
    });

    const setPageMounted = () => {
      if (typeof route.query.page == 'string' && route.query.page) {
        page.value = parseInt(route.query.page);
      }
    };

    /**
     * Изменение страницы
     */
    const setPage = (newPage: number, skipChange = false): void => {
      if (skipChange) {
        page.value = newPage;
      } else {
        if (newPage >= 1 && newPage <= props.totalPages) {
          if (props.setQuery) {
            router.push({ query: { page: newPage } }).then(() => {
              emit('change', newPage);
            });
          } else {
            emit('change', newPage);
          }

          page.value = newPage;
        }
      }
    };

    const resetPage = (): void => {
      page.value = 1;
    };

    setPageMounted();

    return {
      page,
      totalResultLabel,
      pageLinks,
      setPage,
      resetPage,
    };
  },
});
</script>

<style lang="scss">
.pages {
  display: flex;
  font-size: 15px;
  font-weight: 400;
  .total {
    margin-right: 10px;
    color: #888b8d;
    span {
      color: #002c6c;
      font-weight: bold;
    }
  }
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      display: block;
      a {
        color: #00b6de;
      }
      &.disabled a {
        color: #888b8d;
        cursor: default;
      }
      &.active a {
        font-weight: bold;
        color: #002c6c;
      }
      + li {
        margin-left: 10px;
      }
    }
  }
}
</style>
